import React from "react";
import { Button, Col, Row, Nav, NavItem, NavLink, Container } from "reactstrap";
import logoWhite from "../assets/images/logo_white.svg";
import { BiSupport, BiHomeSmile } from "react-icons/bi";
import { FiYoutube, FiFacebook } from "react-icons/fi";
import { TiGroupOutline } from "react-icons/ti";
import { RiTwitterLine } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa";
import { SiTelegram } from "react-icons/si";

const HomeFooter = () => {
  return (
    <footer className="bg-second py-5">
      <Container className="py-0 text-center text-xl-right py-xl-5">
        <Row>
          <Col xl="5" className="d-flex align-items-start">
            <div className="mb-5 mb-xl-0 w-100">
              <div style={{ marginTop: "-5px" }}>
                <img alt="Affilih" className="" style={{ width: "6em" }} src={logoWhite} />
              </div>
              <p className="my-4 text-white text-justify">
                منصة Affilih لإنشاء أنظمة التسويق بالعمولة بضغطة واحدة لإدارة المسوقين والتجار مندوبين الشحن مربوط بدومين شركتك مع دعم فني علي مدار الـ24 ساعة.
              </p>
            </div>
          </Col>
          <Col xl="7" className="d-none d-md-flex align-items-center">
            <Row className="w-100">
              <Col md="6">
                <div className="divider-v divider-v-lg opacity-1 d-none d-xl-block" />
                <div className="pr-0 pr-lg-3 text-right">
                  <h6 className="text-white font-weight-bold mb-3">بعض من مميزات Affilih</h6>
                  <Nav className="nav-transparent flex-column pr-0">
                    <NavItem>
                      <p className="d-block d-xl-flex px-0 py-1 text-white">إنشاء نظام تسويق بالعمولة</p>
                    </NavItem>
                    <NavItem>
                      <p className="d-block d-xl-flex px-0 py-1 text-white">اشعارات فورية لكل المستخدمين</p>
                    </NavItem>
                    <NavItem>
                      <p className="d-block d-xl-flex px-0 py-1 text-white">إدارة الطلبات</p>
                    </NavItem>
                    <NavItem>
                      <p className="d-block d-xl-flex px-0 py-1 text-white">متابعة المصروفات</p>
                    </NavItem>
                    <NavItem>
                      <p className="d-block d-xl-flex px-0 py-1 text-white">محاسبة المسوقين</p>
                    </NavItem>
                    <NavItem>
                      <p className="d-block d-xl-flex px-0 py-1 text-white">سهولة التسجيل</p>
                    </NavItem>
                    <NavItem>
                      <p className="d-block d-xl-flex px-0 py-1 text-white">إنشاء تقارير شاملة ومفصلة</p>
                    </NavItem>
                    <NavItem>
                      <p className="d-block d-xl-flex px-0 py-1 text-white">ربط منتجاتك بمتجر الكتروني احترافي</p>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <Col md="6">
                <div className="divider-v divider-v-lg opacity-1 d-none d-xl-block" />
                <div className="pr-0 pr-lg-3 text-right">
                  <h6 className="text-white font-weight-bold mb-3" title="sitemap">
                    خريطة المنصة - Sitemap
                  </h6>
                  <Nav className="nav-transparent flex-column pr-0 ">
                    <NavItem>
                      <NavLink href="/affiliator" className="d-block d-xl-flex px-0 py-1 text-white">
                        الانضمام كامسوق
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink target="_blank" href="https://affiliator.affilih.com" className="d-block d-xl-flex px-0 py-1 text-white">
                        تصفح الانظمة المشتركة
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/pricing" className="d-block d-xl-flex px-0 py-1 text-white">
                        الاسعار
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/about-us" className="d-block d-xl-flex px-0 py-1 text-white">
                        معلومات عنا
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/contact-us" className="d-block d-xl-flex px-0 py-1 text-white">
                        اتصل بنا
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/privacy-policy" className="d-block d-xl-flex px-0 py-1 text-white">
                        سياسة الخصوصية
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink target="_blank" href="/terms-and-condition.html" className="d-block d-xl-flex px-0 py-1 text-white">
                        الشروط والأحكام
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink target="_blank" href="https://blog.affilih.com/" className="d-block d-xl-flex px-0 py-1 text-white">
                        المدونة
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="divider border-2 d-none d-md-block rounded-circle border-white bg-white opacity-2 mx-auto my-5 w-50" />
        <div className="py-3 ltr">
          <div className="header-top-section bg-white-5 rounded p-2">
            <div className="d-flex header-nav-menu justify-content-between align-items-center navbar-dark">
              <ul className="d-flex">
                <li className="ml-0">
                  <Button
                    color="link"
                    className="rounded-sm d-30 p-0 btn-icon btn-transition-none"
                    target="_blank"
                    rel="noopener"
                    href={`https://t.me/affilih`}
                  >
                    <span className="btn-wrapper--icon">
                      <SiTelegram size="18px" />
                    </span>
                  </Button>
                </li>
                <li className="ml-0">
                  <Button
                    color="link"
                    className="rounded-sm d-30 p-0 btn-icon btn-transition-none"
                    target="_blank"
                    rel="noopener"
                    href={`https://api.whatsapp.com/send?phone=201110918739`}
                  >
                    <span className="btn-wrapper--icon">
                      <FaWhatsapp size="18px" />
                    </span>
                  </Button>
                </li>
                <li className="ml-0">
                  <Button
                    color="link"
                    className="rounded-sm d-30 p-0 btn-icon btn-transition-none"
                    target="_blank"
                    rel="noopener"
                    href={`https://twitter.com/affilih`}
                  >
                    <span className="btn-wrapper--icon">
                      <RiTwitterLine size="18px" />
                    </span>
                  </Button>
                </li>
                <li className="ml-0">
                  <Button
                    color="link"
                    className="rounded-sm d-30 p-0 btn-icon btn-transition-none"
                    target="_blank"
                    rel="noopener"
                    href={`https://www.facebook.com/groups/affilih`}
                  >
                    <span className="btn-wrapper--icon">
                      <TiGroupOutline size="18px" />
                    </span>
                  </Button>
                </li>
                <li className="ml-0">
                  <Button
                    color="link"
                    className="rounded-sm d-30 p-0 btn-icon btn-transition-none"
                    target="_blank"
                    rel="noopener"
                    href={`https://www.facebook.com/affilih`}
                  >
                    <span className="btn-wrapper--icon">
                      <FiFacebook size="18px" />
                    </span>
                  </Button>
                </li>
              </ul>
              <ul className="d-flex">
                <li className="ml-0">
                  <Button
                    color="link"
                    className="rounded-sm d-30 p-0 btn-icon btn-transition-none"
                    target="_blank"
                    rel="noopener"
                    href={`https://www.youtube.com/watch?v=7DALylSeJG0&list=PLLPIku5jqgSjo0yoW2MIrskUr9Du5_4_W&index=1`}
                  >
                    <span className="btn-wrapper--icon">
                      <FiYoutube size="18px" />
                    </span>
                  </Button>
                </li>
                <li className="ml-0">
                  <Button
                    color="link"
                    className="rounded-sm d-30 p-0 btn-icon btn-transition-none"
                    target="_blank"
                    rel="noopener"
                    href={`https://m.me/affilih`}
                  >
                    <span className="btn-wrapper--icon">
                      <BiSupport size="18px" />
                    </span>
                  </Button>
                </li>
                <li className="ml-0">
                  <Button color="link" className="rounded-sm d-30 p-0 btn-icon btn-transition-none" href={`/`}>
                    <span className="btn-wrapper--icon">
                      <BiHomeSmile size="18px" />
                    </span>
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};
export default HomeFooter;
