import React from "react";
import { Row, Col } from "reactstrap";
import CustomContainer from "../components/CustomContainer";
import logoWhite from "../assets/images/logo_white.svg";
import { AiOutlineNumber } from "react-icons/ai";

const SectionStyle = ({ title, paragraph }) => (
  <section className="mt-2">
    <h4 className="display-4 font-weight-bold mb-3">
      <AiOutlineNumber className="ml-1" size="25px" /> {title}
    </h4>
    {paragraph && <p className="font-weight-bold font-size-xl m-0 pb-3 mb-5 text-right text-white opacity-7">{paragraph}</p>}
  </section>
);

const PrivacyPolicy = () => {
  return (
    <>
      <CustomContainer
        Children={
          <Row className="py-5 rtl">
            <Col lg="12">
              <div className="text-center text-lg-left py-0 pr-0 pr-xl-3 py-xl-5 w-100">
                <div className="pr-0 pl-xl-5 text-white rtl w-100 d-flex flex-column justify-content-center justify-content-md-start">
                  <div className="font-size-sm d-inline-flex bg-white-10 px-4 rounded-lg text-white py-2 text-uppercase mt-3 mx-auto mr-md-0 ml-md-auto">
                    <img alt="Affilih Logo" className="mb-2" style={{ width: "8em" }} src={logoWhite} />
                  </div>
                  <h1 className="font-weight-bold mt-4 text-uppercase customH1 text-center text-md-right">الخصوصية وسريّة المعلومات</h1>
                  {/* Questions */}
                  <div className="w-100 mt-5" />
                  <SectionStyle
                    title="نقدر مخاوفكم واهتمامكم بشأن خصوصية بياناتكم على شبكة الإنترنت"
                    paragraph="لقد تم إعداد هذه السياسة لمساعدتكم في تفهم طبيعة البيانات التي نقوم بتجميعها منكم عند زيارتكم لموقعنا على شبكة الانترنت وكيفية تعاملنا مع هذه البيانات الشخصية."
                  />
                  <SectionStyle
                    title="التصفح"
                    paragraph="لم نقم بتصميم هذا الموقع من أجل تجميع بياناتك الشخصية من جهاز الكمبيوتر الخاص بك أثناء تصفحك لهذا الموقع, وإنما سيتم فقط استخدام البيانات المقدمة من قبلك بمعرفتك ومحض إرادتك."
                  />
                  <SectionStyle
                    title="عنوان بروتوكول شبكة الإنترنت (IP)"
                    paragraph="في أي وقت تزور فيه اي موقع انترنت بما فيها هذا الموقع , سيقوم السيرفر المضيف بتسجيل عنوان بروتوكول شبكة الإنترنت (IP) الخاص بك , تاريخ ووقت الزيارة ونوع متصفح الإنترنت الذي تستخدمه والعنوان URL الخاص بأي موقع من مواقع الإنترنت التي تقوم بإحالتك إلى الى هذا الموقع على الشبكة."
                  />
                  <SectionStyle
                    title="عمليات المسح على الشبكة"
                    paragraph="إن عمليات المسح التي نقوم بها مباشرة على الشبكة تمكننا من تجميع بيانات محددة مثل البيانات المطلوبة منك بخصوص نظرتك وشعورك تجاه موقعنا.تعتبر ردودك ذات أهمية قصوى , ومحل تقديرنا حيث أنها تمكننا من تحسين مستوى موقعنا, ولك كامل الحرية والإختيار في تقديم البيانات المتعلقة بإسمك والبيانات الأخرى."
                  />
                  <SectionStyle
                    title="الروابط بالمواقع الأخرى على شبكة الإنترنت"
                    paragraph="نحن قد نستعين بشركات إعلان لأطراف ثالثة لعرض الإعلانات عندما تزور موقعنا على الويب. يحق لهذه الشركات أن تستخدم معلومات حول زياراتك لهذا الموقع ولمواقع الويب الأخرى (باستثناء الاسم أو العنوان أو عنوان البريد الإلكتروني أو رقم الهاتف)، وذلك من أجل تقديم إعلانات حول البضائع والخدمات التي تهمك."
                  />
                  <SectionStyle
                    title="إفشاء المعلومات"
                    paragraph="سنحافظ في كافة الأوقات على خصوصية وسرية كافة البيانات الشخصية التي نتحصل عليها. ولن يتم إفشاء هذه المعلومات إلا إذا كان ذلك مطلوباً بموجب أي قانون أو عندما نعتقد بحسن نية أن مثل هذا الإجراء سيكون مطلوباً أو مرغوباً فيه للتمشي مع القانون , أو للدفاع عن أو حماية حقوق الملكية الخاصة بهذا الموقع أو الجهات المستفيدة منه."
                  />
                  <SectionStyle
                    title="البيانات اللازمة لتنفيذ المعاملات المطلوبة من قبلك"
                    paragraph="عندما نحتاج إلى أية بيانات خاصة بك , فإننا سنطلب منك تقديمها بمحض إرادتك. حيث ستساعدنا هذه المعلومات في الاتصال بك وتنفيذ طلباتك حيثما كان ذلك ممكنناً. لن يتم اطلاقاً بيع البيانات المقدمة من قبلك إلى أي طرف ثالث بغرض تسويقها لمصلحته الخاصة دون الحصول على موافقتك المسبقة والمكتوبة ما لم يتم ذلك على أساس أنها ضمن بيانات جماعية تستخدم للأغراض الإحصائية والأبحاث دون اشتمالها على أية بيانات من الممكن استخدامها للتعريف بك."
                  />
                  <SectionStyle
                    title="عند الاتصال بنا"
                    paragraph="سيتم التعامل مع كافة البيانات المقدمة من قبلك على أساس أنها سرية . تتطلب النماذج التي يتم تقديمها مباشرة على الشبكة تقديم البيانات التي ستساعدنا في تحسين موقعنا.سيتم استخدام البيانات التي يتم تقديمها من قبلك في الرد على كافة استفساراتك , ملاحظاتك , أو طلباتك من قبل هذا الموقع أو أيا من المواقع التابعة له ."
                  />
                  <SectionStyle
                    title="إفشاء المعلومات لأي طرف ثالث"
                    paragraph="لن نقوم ببيع , المتاجرة , تأجير , أو إفشاء أية معلومات لمصلحة أي طرف ثالث خارج هذا الموقع, أو المواقع التابعة له.وسيتم الكشف عن المعلومات فقط في حالة صدور أمر بذلك من قبل أي سلطة قضائية أو تنظيمية."
                  />
                  <SectionStyle
                    title="التعديلات على سياسة سرية وخصوصية المعلومات"
                    paragraph="نحتفظ بالحق في تعديل بنود وشروط سياسة سرية وخصوصية المعلومات إن لزم الأمر ومتى كان ذلك ملائماً. سيتم تنفيذ التعديلات هنا وسيتم بصفة مستمرة إخطارك بالبيانات التي حصلنا عليها , وكيف سنستخدمها والجهة التي سنقوم بتزويدها بهذه البيانات."
                  />
                  <SectionStyle title="الاتصال بنا" paragraph="يمكنكم الاتصال بنا عند الحاجة من خلال الضغط على رابط اتصل بنا المتوفر في روابط موقعنا" />
                  <SectionStyle
                    title="اخيرا"
                    paragraph="إن مخاوفك واهتمامك بشأن سرية وخصوصية البيانات تعتبر مسألة في غاية الأهمية بالنسبة لنا. نحن نأمل أن يتم تحقيق ذلك من خلال هذه السياسة."
                  />
                </div>
              </div>
            </Col>
          </Row>
        }
      />
    </>
  );
};

export default PrivacyPolicy;
