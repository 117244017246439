
import {
  apiAuthPlatform,
  apiLoginPlatform,
  apiGetStories,
  apiGetStoreData,
  apiPutStoreData,
  // apiGetAllPlatformAuthentication,
  // apiGetPlatformAuthenticationByID,
  // apiSavePlatformAuthentication,
  // apiUpdatePlatformAuthenticationByID,
  // apiDeletePlatformAuthenticationByID,
  // apiDeleteAllPlatformAuthentication,
} from "../api/";

import { HandelMyError } from "../store/Redux.Hander.js";
import setAuthHeader from "../api/setAuthHeader";

const PLATFORM_RESETALL = "GETALLSUCCESSPLATFORM_RESETALL"

const GETALLLOADINGPLATFORM = "GETALLLOADINGPLATFORM"
const GETALLSUCCESSPLATFORM = "GETALLSUCCESSPLATFORM"
const GETALLFAILEDPLATFORM = "GETALLFAILEDPLATFORM"

const DELETEALLLOADINGPLATFORM = "DELETEALLLOADINGPLATFORM"
const DELETEALLSUCCESSPLATFORM = "DELETEALLSUCCESSPLATFORM"
const DELETEALLFAILEDPLATFORM = "DELETEALLFAILEDPLATFORM"

const GETPLATFORM_PROFILE_RESET = "GETPLATFORM_PROFILE_RESET"
const GETPLATFORM_PROFILE_LOADING = "GETPLATFORM_PROFILE_LOADING"
const GETPLATFORM_PROFILE_SUCCESS = "GETPLATFORM_PROFILE_SUCCESS"
const GETPLATFORM_PROFILE_FAILED = "GETPLATFORM_PROFILE_FAILED"

const PLATFORM_AUTH_RESET = "PLATFORM_AUTH_RESET"
const GETPLATFORM_AUTH_LOADING = "GETPLATFORM_AUTH_LOADING"
const GETPLATFORM_AUTH_SUCCESS = "GETPLATFORM_AUTH_SUCCESS"
const GETPLATFORM_AUTH_FAILED = "GETPLATFORM_AUTH_FAILED"

const SAVELOADINGPLATFORM = "SAVELOADINGPLATFORM"
const SAVESUCCESSPLATFORM = "SAVESUCCESSPLATFORM"
const SAVEFAILEDPLATFORM = "SAVEFAILEDPLATFORM"

const UPDATELOADINGPLATFORM = "UPDATELOADINGPLATFORM"
const UPDATESUCCESSPLATFORM = "UPDATESUCCESSPLATFORM"
const UPDATEFAILEDPLATFORM = "UPDATEFAILEDPLATFORM"

const GETPLATFORM_STORIES_LOADING = "GETPLATFORM_STORIES_LOADING"
const GETPLATFORM_STORIES_SUCCESS = "GETPLATFORM_STORIES_SUCCESS"
const GETPLATFORM_STORIES_FAILED = "GETPLATFORM_STORIES_FAILED"

const GETDATA_STORIES_LOADING = "GETDATA_STORIES_LOADING"
const GETDATA_STORIES_SUCCESS = "GETDATA_STORIES_SUCCESS"
const GETDATA_STORIES_FAILED = "GETDATA_STORIES_FAILED"

const PUTDATA_STORIES_LOADING = "PUTDATA_STORIES_LOADING"
const PUTDATA_STORIES_SUCCESS = "PUTDATA_STORIES_SUCCESS"
const PUTDATA_STORIES_FAILED = "PUTDATA_STORIES_FAILED"

// const DELETELOADINGPLATFORM = "DELETELOADINGPLATFORM"
// const DELETESUCCESSPLATFORM = "DELETESUCCESSPLATFORM"
// const DELETEFAILEDPLATFORM = "DELETEFAILEDPLATFORM"
const TOKEN_NAME = "TOKEN_NAME";

export const resetAllPlatformAuthentication = () => {
  return (dispatch) => {
    dispatch({ type: PLATFORM_RESETALL });
  };
};

// export const getAllPlatformAuthentication = () => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: PLATFORM_RESETALL });
//       dispatch({ type: GETALLLOADINGPLATFORM });
//       const { data: { platformAuthentications } } = await apiGetAllPlatformAuthentication();
//       dispatch({ type: GETALLSUCCESSPLATFORM, payload: { platformAuthentications } });
//     } catch (e) {
//       dispatch({ type: GETALLFAILEDPLATFORM, payload: HandelMyError(e) });
//     }
//   };
// };
// export const deleteAllPlatformAuthentication = (id) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: PLATFORM_RESETALL });
//       dispatch({ type: DELETEALLLOADINGPLATFORM });
//       const { data: { success } } = await apiDeleteAllPlatformAuthentication();
//       dispatch({ type: DELETEALLSUCCESSPLATFORM, payload: success });
//     } catch (e) {
//       dispatch({ type: DELETEALLFAILEDPLATFORM, payload: HandelMyError(e) });
//     }
//   };
// };
const successLoginPlatform = (token) => {
  localStorage.setItem(TOKEN_NAME, token);
  return { type: GETPLATFORM_AUTH_SUCCESS };
};

export const fetchPlatformProfile = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GETPLATFORM_PROFILE_RESET });
      dispatch({ type: GETPLATFORM_PROFILE_LOADING });
      const { data: { user } } = await apiLoginPlatform();
      dispatch({ type: GETPLATFORM_PROFILE_SUCCESS, payload: user });
    } catch (e) {
      if (e?.response?.data?.removeToken) {
        localStorage.removeItem(TOKEN_NAME);
      }
      dispatch({ type: GETPLATFORM_PROFILE_FAILED, payload: HandelMyError(e) });
    }
  };
};

export const loginPlatformAuthentication = (fromData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PLATFORM_AUTH_RESET });
      dispatch({ type: GETPLATFORM_AUTH_LOADING });
      const { data: { token } } = await apiAuthPlatform(fromData);
      setAuthHeader(token);
      dispatch(fetchPlatformProfile());
      dispatch(successLoginPlatform(token));
    } catch (e) {
      if (e?.response?.data?.removeToken) {
        localStorage.removeItem(TOKEN_NAME);
      }
      dispatch({ type: GETPLATFORM_AUTH_FAILED, payload: HandelMyError(e) });
    }
  };
};

export const getStories = (filter) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GETPLATFORM_STORIES_LOADING });
      const { data: { stories } } = await apiGetStories(filter);
      dispatch({ type: GETPLATFORM_STORIES_SUCCESS, payload: { stories } });
    } catch (e) {
      dispatch({ type: GETPLATFORM_STORIES_FAILED, payload: HandelMyError(e) });
    }
  };
};
export const getStoreData = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GETDATA_STORIES_LOADING });
      const { data: { store } } = await apiGetStoreData(id);
      dispatch({ type: GETDATA_STORIES_SUCCESS, payload: { store } });
    } catch (e) {
      dispatch({ type: GETDATA_STORIES_FAILED, payload: HandelMyError(e) });
    }
  };
};

export const putStoreData = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PUTDATA_STORIES_LOADING });
      await apiPutStoreData(id, data);
      dispatch({ type: PUTDATA_STORIES_SUCCESS });
      dispatch(getStoreData(id));

    } catch (e) {
      dispatch({ type: PUTDATA_STORIES_FAILED, payload: HandelMyError(e) });
    }
  };
};


// export const savePlatformAuthentication = (platformAuthentication) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: SAVELOADINGPLATFORM });
//       const { data: { success } } = await apiSavePlatformAuthentication(platformAuthentication);
//       dispatch({ type: SAVESUCCESSPLATFORM, payload: success });
//     } catch (e) {
//       dispatch({ type: SAVEFAILEDPLATFORM, payload: HandelMyError(e) });
//     }
//     try {
//       dispatch({ type: GETLOADINGPLATFORM });
//       const { data: { platformAuthentication } } = await apiGetPlatformAuthenticationByID();
//       dispatch({ type: GETSUCCESSPLATFORM, payload: { platformAuthentication } });
//     } catch (e) {
//       dispatch({ type: GETFAILEDPLATFORM, payload: HandelMyError(e) });
//     }
//   };
// };

// export const updatePlatformAuthenticationByID = (id, data) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: UPDATELOADINGPLATFORM });
//       const { data: { success } } = await apiUpdatePlatformAuthenticationByID(id, data);
//       dispatch({ type: UPDATESUCCESSPLATFORM, payload: success });
//     } catch (e) {
//       dispatch({ type: UPDATEFAILEDPLATFORM, payload: HandelMyError(e) });
//     }
//     try {
//       dispatch({ type: GETLOADINGPLATFORM });
//       const { data: { platformAuthentication } } = await apiGetPlatformAuthenticationByID(id);
//       dispatch({ type: GETSUCCESSPLATFORM, payload: { platformAuthentication } });
//     } catch (e) {
//       dispatch({ type: GETFAILEDPLATFORM, payload: HandelMyError(e) });
//     }
//   };
// };


const INITIAL_STATE = {
  getPlatformAuthLoading: false,
  PlatformAuth: false,
  getPlatformAuthFailed: null,

  getPlatformProfileLoading: false,
  PlatformProfile: {},
  getPlatformProfileFailed: null,

  getPlatformStoriesLoading: true,
  getPlatformStoriesSuccess: null,
  getPlatformStoriesFailed: null,

  getAllLoadingPlatformAuthentication: false,
  getAllSuccessPlatformAuthentication: null,
  getAllFailedPlatformAuthentication: null,

  deleteAllLoadingPlatformAuthentication: false,
  deleteAllSuccessPlatformAuthentication: null,
  deleteAllFailedPlatformAuthentication: null,

  saveLoadingPlatformAuthentication: false,
  saveSuccessPlatformAuthentication: null,
  saveFailedPlatformAuthentication: null,

  updateLoadingPlatformAuthentication: false,
  updateSuccessPlatformAuthentication: null,
  updateFailedPlatformAuthentication: null,

  getDataStoriesLoading: false,
  getDataStoriesSuccess: null,
  getDataStoriesFailed: null,

  putDataStoriesLoading: false,
  putDataStoriesSuccess: null,
  putDataStoriesFailed: null

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PLATFORM_RESETALL: return {
      ...state,

      getPlatformAuthLoading: false,
      PlatformAuth: false,
      getPlatformAuthFailed: null,

      getPlatformProfileLoading: false,
      PlatformProfile: {},
      getPlatformProfileFailed: null,

      getPlatformStoriesLoading: true,
      getPlatformStoriesSuccess: null,
      getPlatformStoriesFailed: null,

      getAllLoadingPlatformAuthentication: false,
      getAllSuccessPlatformAuthentication: null,
      getAllFailedPlatformAuthentication: null,

      deleteAllLoadingPlatformAuthentication: false,
      deleteAllSuccessPlatformAuthentication: null,
      deleteAllFailedPlatformAuthentication: null,

      saveLoadingPlatformAuthentication: false,
      saveSuccessPlatformAuthentication: null,
      saveFailedPlatformAuthentication: null,

      updateLoadingPlatformAuthentication: false,
      updateSuccessPlatformAuthentication: null,
      updateFailedPlatformAuthentication: null,

      deleteLoadingPlatformAuthentication: false,
      deleteSuccessPlatformAuthentication: null,
      deleteFailedPlatformAuthentication: null,
    };

    case GETALLLOADINGPLATFORM: return { ...state, getAllLoadingPlatformAuthentication: true, getAllSuccessPlatformAuthentication: null, getAllFailedPlatformAuthentication: null };
    case GETALLSUCCESSPLATFORM: return { ...state, getAllLoadingPlatformAuthentication: false, getAllSuccessPlatformAuthentication: action.payload || {}, getAllFailedPlatformAuthentication: null };
    case GETALLFAILEDPLATFORM: return { ...state, getAllLoadingPlatformAuthentication: false, getAllSuccessPlatformAuthentication: null, getAllFailedPlatformAuthentication: action.payload || "" };

    case DELETEALLLOADINGPLATFORM: return { ...state, deleteAllLoadingPlatformAuthentication: true, deleteAllSuccessPlatformAuthentication: null, deleteAllFailedPlatformAuthentication: null };
    case DELETEALLSUCCESSPLATFORM: return { ...state, deleteAllLoadingPlatformAuthentication: false, deleteAllSuccessPlatformAuthentication: action.payload || "", deleteAllFailedPlatformAuthentication: null };
    case DELETEALLFAILEDPLATFORM: return { ...state, deleteAllLoadingPlatformAuthentication: false, deleteAllSuccessPlatformAuthentication: null, deleteAllFailedPlatformAuthentication: action.payload || "" };

    // Get Stories data
    case GETDATA_STORIES_LOADING: return {
      ...state,
      getDataStoriesLoading: true,
      getDataStoriesSuccess: null,
      getDataStoriesFailed: null
    };
    case GETDATA_STORIES_SUCCESS: return {
      ...state,
      getDataStoriesLoading: false,
      getDataStoriesSuccess: action.payload || null,
      getDataStoriesFailed: null
    };
    case GETDATA_STORIES_FAILED: return {
      ...state,
      getDataStoriesLoading: false,
      getDataStoriesSuccess: null,
      getDataStoriesFailed: action.payload || null
    };

    // Put Stories data
    case PUTDATA_STORIES_LOADING: return {
      ...state,
      putDataStoriesLoading: true,
      putDataStoriesSuccess: null,
      putDataStoriesFailed: null
    };
    case PUTDATA_STORIES_SUCCESS: return {
      ...state,
      putDataStoriesLoading: false,
      putDataStoriesSuccess: action.payload || null,
      putDataStoriesFailed: null
    };
    case PUTDATA_STORIES_FAILED: return {
      ...state,
      putDataStoriesLoading: false,
      putDataStoriesSuccess: null,
      putDataStoriesFailed: action.payload || null
    };

    // Get Stories
    case GETPLATFORM_STORIES_LOADING: return {
      ...state,
      getPlatformStoriesLoading: true,
      getPlatformStoriesSuccess: null,
      getPlatformStoriesFailed: null
    };
    case GETPLATFORM_STORIES_SUCCESS: return {
      ...state,
      getPlatformStoriesLoading: false,
      getPlatformStoriesSuccess: action.payload || null,
      getPlatformStoriesFailed: null
    };
    case GETPLATFORM_STORIES_FAILED: return {
      ...state,
      getPlatformStoriesLoading: false,
      getPlatformStoriesSuccess: null,
      getPlatformStoriesFailed: action.payload || null
    };

    // Login
    case PLATFORM_AUTH_RESET: return {
      ...state,
      getPlatformAuthLoading: false,
      PlatformAuth: false,
      getPlatformAuthFailed: null
    };
    case GETPLATFORM_AUTH_LOADING: return {
      ...state,
      getPlatformAuthLoading: true,
      PlatformAuth: false,
      getPlatformAuthFailed: null
    };
    case GETPLATFORM_AUTH_SUCCESS: return {
      ...state,
      getPlatformAuthLoading: false,
      PlatformAuth: true,
      getPlatformAuthFailed: null
    };
    case GETPLATFORM_AUTH_FAILED: return {
      ...state,
      getPlatformAuthLoading: false,
      PlatformAuth: false,
      getPlatformAuthFailed: action.payload || null
    };

    // Get Profile
    case GETPLATFORM_PROFILE_RESET: return {
      ...state,
      getPlatformProfileLoading: false,
      PlatformProfile: {},
      getPlatformProfileFailed: null
    };
    case GETPLATFORM_PROFILE_LOADING: return {
      ...state,
      getPlatformProfileLoading: true,
      PlatformProfile: {},
      getPlatformProfileFailed: null
    };
    case GETPLATFORM_PROFILE_SUCCESS: return {
      ...state,
      getPlatformProfileLoading: false,
      PlatformProfile: action.payload || {},
      getPlatformProfileFailed: null
    };
    case GETPLATFORM_PROFILE_FAILED: return {
      ...state,
      getPlatformProfileLoading: false,
      PlatformProfile: {},
      getPlatformProfileFailed: action.payload || ""
    };

    case SAVELOADINGPLATFORM: return { ...state, saveLoadingPlatformAuthentication: true, saveSuccessPlatformAuthentication: null, saveFailedPlatformAuthentication: null };
    case SAVESUCCESSPLATFORM: return { ...state, saveLoadingPlatformAuthentication: false, saveSuccessPlatformAuthentication: action.payload || "", saveFailedPlatformAuthentication: null };
    case SAVEFAILEDPLATFORM: return { ...state, saveLoadingPlatformAuthentication: false, saveSuccessPlatformAuthentication: null, saveFailedPlatformAuthentication: action.payload || "" };

    case UPDATELOADINGPLATFORM: return { ...state, updateLoadingPlatformAuthentication: true, updateSuccessPlatformAuthentication: null, updateFailedPlatformAuthentication: null };
    case UPDATESUCCESSPLATFORM: return { ...state, updateLoadingPlatformAuthentication: false, updateSuccessPlatformAuthentication: action.payload || "", updateFailedPlatformAuthentication: null };
    case UPDATEFAILEDPLATFORM: return { ...state, updateLoadingPlatformAuthentication: false, updateSuccessPlatformAuthentication: null, updateFailedPlatformAuthentication: action.payload || "" };
    default: return state;
  }
};

