import React from "react";
import { Row, Col } from "reactstrap";
import CustomContainer from "../components/CustomContainer";
import logoWhite from "../assets/images/logo_white.svg";
import { AiOutlineNumber } from "react-icons/ai";

const SectionStyle = ({ title, paragraph }) => (
  <section className="mt-5">
    <h4 className="display-4 font-weight-bold mb-3">
      <AiOutlineNumber className="ml-1" size="25px" /> {title}
    </h4>
    <p className="font-weight-bold font-size-xl m-0 pb-3 mb-5 text-right text-white opacity-7">{paragraph}</p>
  </section>
);

const AboutUs = () => {
  return (
    <>
      <CustomContainer
        Children={
          <Row className="py-5 rtl">
            <Col lg="12">
              <div className="text-center text-lg-left py-0 pr-0 pr-xl-3 py-xl-5 w-100">
                <div className="pr-0 pl-xl-5 text-white rtl w-100 d-flex flex-column justify-content-center justify-content-md-start">
                  <div className="font-size-sm d-inline-flex bg-white-10 px-4 rounded-lg text-white py-2 text-uppercase mt-3 mx-auto mr-md-0 ml-md-auto">
                    <img alt="Affilih Logo" className="mb-2" style={{ width: "8em" }} src={logoWhite} />
                  </div>
                  <h1 className="font-weight-bold mt-4 text-uppercase customH1 text-center text-md-right">معلومات عنا</h1>
                  {/* Questions */}
                  <div className="w-100 mt-5" />
                  <SectionStyle
                    title="من نحن"
                    paragraph="منصة Affilih هي شركة مصرية تقدم خدمات حلول فعالة لإدارة المسوقين، متواجدة وبقوة في السوق منذ عام 2019, لنا قاعدة كبيرة من العملاء من مختلف دول العالم العربي الذين استطاعنا ان ننال على ثقتهم من خلال تعاملنا معهم في هذه السنوات, نوفر نظام تسويق بالعمولة يمكن عملائنا من إدارة المتسوقين والتجار ومندوبين الشحن والطلبات والمصروفات والعمولات والمستخدمين والكثير من الخدمات المتعلقة مع دعم فني متواصل على مدار الـ24 ساعة مع ضمان جودة الخدمة، وكان هذا بسبب اصرارنا دائما على ملاحقة التطور المستمر في تكنولوجيا المعلومات لتقديم خدماتنا بأحدث التقنيات المتوفرة دائما, بالاضافة الى اننا نملك فريق عمل يتمتع بالخبرات العملية والإدارية لضمان جودة الخدمة المقدمة."
                  />
                  <SectionStyle
                    title="الاهداف"
                    paragraph="إن اتساع قنوات التسويق الرقمي ليست الميزة الوحيدة التي تدفع رواد الأعمال لاستخدام التسويق الرقمي اليوم، لكن في الواقع هناك العديد من الفوائد التي تسلط الضوء على أهمية التسويق الرقمي، وهو أمر لا غنى عنه لرواد الأعمال ومن هنا بدأت فكرة Affilih في توفير الوقت والمجهود على رواد الأعمال والتركيز على ادارة المسوقين والتجار ومندوبين او شركات الشحن."
                  />
                </div>
              </div>
            </Col>
          </Row>
        }
      />
    </>
  );
};

export default AboutUs;
