import {
  apiCheckDomainIsReady,
  apiSendOTP,
  apiSaveStoreData,
  apiCheckDomainStore,
  apiConfirmDomainStore,
  apiGetProfile,
  apiGetStore,
  apiAddStore,
  apiSaveAffiliator,
  apipushAbousUsSocialLinks,
  apipushCities,
  apiUpdatePushCitiesCities,
  apiRemoveCities,
  apiLoginAuthentication,
  apiRegisterAuthentication,
  apiUpdateProfile,
  apiAddPaymentMethod,
  apiRemovePayment,
  apiupdateSettings,
  apiGetAllSectionInSettings,
  apipushSections,
  apiPushCondition,
  apiUpdatePushSections,
  apiRemoveSections,
  apiRemoveAbousUsSocialLinks,
  apiRemoveCondition,
  apiGetUserTreeById,
} from "../api/";
import setAuthHeader from "../api/setAuthHeader";
import removeAuthHeader from "../api/removeAuthHeader";

import { HandelMyError } from "../store/Redux.Hander.js";
const TOKEN_NAME = "TOKEN_NAME";
export const UserLoggedOut = "UserLoggedOut";

const AUTHENTICATION_RESET = "GETALLLOADINGAUTHENTICATION_RESET";
const AUTHENTICATION_RESETALL = "GETALLSUCCESSAUTHENTICATION_RESETALL";

const SAVELOADINGPSTORE = "SAVELOADINGPSTORE";
const SAVESUCCESSSTORE = "SAVESUCCESSSTORE";
const SAVEFAILEDSTORE = "SAVEFAILEDSTORE";

const SAVELOADINGPAFFILITOR = "SAVELOADINGPAFFILITOR";
const SAVESUCCESSAFFILITOR = "SAVESUCCESSAFFILITOR";
const SAVEFAILEDAFFILITOR = "SAVEFAILEDAFFILITOR";

const STOREIMAGES_LOADING = "STOREIMAGES_LOADING";
const STOREIMAGES_SUCCESS = "STOREIMAGES_SUCCESS";
const STOREIMAGES_FAILED = "STOREIMAGES_FAILED";

const CHECKDOMAINISREADY_REATTEMPT = "CHECKDOMAINISREADY_REATTEMPT";
const CHECKDOMAINISREADY_LOADING = "CHECKDOMAINISREADY_LOADING";
const CHECKDOMAINISREADY_SUCCESS = "CHECKDOMAINISREADY_SUCCESS";
const CHECKDOMAINISREADY_FAILED = "CHECKDOMAINISREADY_FAILED";

const RESET_CHECKDOMAINLOADINGPSTORE = "RESET_CHECKDOMAINLOADINGPSTORE";

const CHECKDOMAINLOADINGPSTORE = "CHECKDOMAINLOADINGPSTORE";
const CHECKDOMAINSUCCESSSTORE = "CHECKDOMAINSUCCESSSTORE";
const CHECKDOMAINFAILEDSTORE = "CHECKDOMAINFAILEDSTORE";

const CONFIRMDOMAINLOADINGPSTORE = "CONFIRMDOMAINLOADINGPSTORE";
const CONFIRMDOMAINSUCCESSSTORE = "CONFIRMDOMAINSUCCESSSTORE";
const CONFIRMDOMAINFAILEDSTORE = "CONFIRMDOMAINFAILEDSTORE";

const SENDOTP_LOADING = "SENDOTP_LOADING";
const SENDOTP_SUCCESS = "SENDOTP_SUCCESS";
const SENDOTP_FAILED = "SENDOTP_FAILED";

const UPDATECONFRIMATIONLOADINGPSTORE = "UPDATECONFRIMATIONLOADINGPSTORE";
const UPDATECONFRIMATIONSUCCESSSTORE = "UPDATECONFRIMATIONSUCCESSSTORE";
const UPDATECONFRIMATIONFAILEDSTORE = "UPDATECONFRIMATIONFAILEDSTORE";

const GETALLLOADINGSECTIONSETTINGS = "GETALLLOADINGSECTIONSETTINGS";
const GETALLSUCCESSSECTIONSETTINGS = "GETALLSUCCESSSECTIONSETTINGS";
const GETALLFAILEDSECTIONSETTINGS = "GETALLFAILEDSECTIONSETTINGS";

const UPDATELOADINGSECTIONSETTING = "UPDATELOADINGSECTIONSETTING";
const UPDATESUCCESSSECTIONSETTING = "UPDATESUCCESSSECTIONSETTING";
const UPDATEFAILEDSECTIONSETTING = "UPDATEFAILEDSECTIONSETTING";

const UPDATELOADINGCONDTIONSETTING = "UPDATELOADINGCONDTIONSETTING";
const UPDATESUCCESSCONDTIONSETTING = "UPDATESUCCESSCONDTIONSETTING";
const UPDATEFAILEDCONDTIONSETTING = "UPDATEFAILEDCONDTIONSETTING";

const GETLOADINGPROFILE = "GETLOADINGPROFILE";
const GETSUCCESSPROFILE = "GETSUCCESSPROFILE";
const GETFAILEDPROFILE = "GETFAILEDPROFILE";

const GETUSERTREE_LOADING = "GETUSERTREE_LOADING";
const GETUSERTREE_SUCCESS = "GETUSERTREE_SUCCESS";
const GETUSERTREE_FAILED = "GETUSERTREE_FAILED";

const GETLOADINGPSTORE = "GETLOADINGPSTORE";
const GETSUCCESSSTORE = "GETSUCCESSSTORE";
const GETFAILEDSTORE = "GETFAILEDSTORE";

const UPDATELOADINGSETTINGS = "UPDATELOADINGSETTINGS";
const UPDATESUCCESSSETTINGS = "UPDATESUCCESSSETTINGS";
const UPDATEFAILEDSETTINGS = "UPDATEFAILEDSETTINGS";

const GETLOADINGAUTHENTICATION = "GETLOADINGAUTHENTICATION";
const GETSUCCESSAUTHENTICATION = "GETSUCCESSAUTHENTICATION";
const GETFAILEDAUTHENTICATION = "GETFAILEDAUTHENTICATION";

const SAVELOADINGAUTHENTICATION = "SAVELOADINGAUTHENTICATION";
const SAVESUCCESSAUTHENTICATION = "SAVESUCCESSAUTHENTICATION";
const SAVEFAILEDAUTHENTICATION = "SAVEFAILEDAUTHENTICATION";

const UPDATELOADINGAUTHENTICATION = "UPDATELOADINGAUTHENTICATION";
const UPDATESUCCESSAUTHENTICATION = "UPDATESUCCESSAUTHENTICATION";
const UPDATEFAILEDAUTHENTICATION = "UPDATEFAILEDAUTHENTICATION";

const DELETELOADINGAUTHENTICATION = "DELETELOADINGAUTHENTICATION";
const DELETESUCCESSAUTHENTICATION = "DELETESUCCESSAUTHENTICATION";
const DELETEFAILEDAUTHENTICATION = "DELETEFAILEDAUTHENTICATION";

export const resetAuthentication = () => {
  return (dispatch) => {
    dispatch({ type: AUTHENTICATION_RESET });
  };
};

export const resetAllAuthentication = () => {
  return (dispatch) => {
    dispatch({ type: AUTHENTICATION_RESETALL });
  };
};

export const onLoadingSignIn = () => {
  return (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_NAME);
      if (token !== null && token !== "undefined") {
        setAuthHeader(token);
        dispatch(fetchStore());
        dispatch(fetchProfile());
        dispatch(successLogin(token));
      } else {
        dispatch(fetchStore());
      }
    } catch (e) {
      dispatch({ type: GETFAILEDAUTHENTICATION, payload: HandelMyError(e) });
    }
  };
};

export const loginAuthentication = (fromData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: AUTHENTICATION_RESET });
      dispatch({ type: GETLOADINGAUTHENTICATION });
      const {
        data: { token },
      } = await apiLoginAuthentication(fromData);
      setAuthHeader(token);
      dispatch(fetchProfile());
      dispatch(successLogin(token));
    } catch (e) {
      dispatch({ type: GETFAILEDAUTHENTICATION, payload: HandelMyError(e) });
    }
  };
};


export const sendOTP = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SENDOTP_LOADING });
      const {
        data: { success },
      } = await apiSendOTP(data);
      dispatch({ type: SENDOTP_SUCCESS, payload: success });
    } catch (e) {
      dispatch({ type: SENDOTP_FAILED, payload: HandelMyError(e) });
    }
  };
};

export const saveStoreData = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATECONFRIMATIONLOADINGPSTORE });
      const {
        data: { success },
      } = await apiSaveStoreData(data);
      dispatch({ type: UPDATECONFRIMATIONSUCCESSSTORE, payload: success });
    } catch (e) {
      dispatch({ type: UPDATECONFRIMATIONFAILEDSTORE, payload: HandelMyError(e) });
    }
    dispatch(fetchStore());
  };
};


export const registerAuthentication = (formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: AUTHENTICATION_RESET });
      dispatch({ type: SAVELOADINGAUTHENTICATION });
      const {
        data: { token, success },
      } = await apiRegisterAuthentication(formData);
      setAuthHeader(token);
      dispatch(successRegister(token, success));
      // dispatch(fetchProfile());
    } catch (e) {
      dispatch({ type: SAVEFAILEDAUTHENTICATION, payload: HandelMyError(e) });
    }
  };
};


export const getUserTreeById = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GETUSERTREE_LOADING });
      const { data: { userTree } } = await apiGetUserTreeById(id);
      dispatch({ type: GETUSERTREE_SUCCESS, payload: { userTree } });
    } catch (e) {
      dispatch({ type: GETUSERTREE_FAILED, payload: HandelMyError(e) });
    }
  };
};

export const update = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGAUTHENTICATION });
      const {
        data: { success },
      } = await apiUpdateProfile(data);
      dispatch(fetchProfile());
      dispatch({ type: UPDATESUCCESSAUTHENTICATION, payload: success });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDAUTHENTICATION, payload: HandelMyError(e) });
    }
  };
};

export const addPaymentMethod = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGAUTHENTICATION });
      const {
        data: { success },
      } = await apiAddPaymentMethod(data);
      dispatch(fetchProfile());
      dispatch({ type: UPDATESUCCESSAUTHENTICATION, payload: success });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDAUTHENTICATION, payload: HandelMyError(e) });
    }
  };
};

export const removePayment = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGAUTHENTICATION });
      const {
        data: { success },
      } = await apiRemovePayment(id);
      dispatch(fetchProfile());
      dispatch({ type: UPDATESUCCESSAUTHENTICATION, payload: success });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDAUTHENTICATION, payload: HandelMyError(e) });
    }
  };
};

export const logUserOut = () => {
  localStorage.removeItem(TOKEN_NAME);
  removeAuthHeader();
  return { type: UserLoggedOut };
};

const successRegister = (token, success) => {
  localStorage.setItem(TOKEN_NAME, token);
  return { type: SAVESUCCESSAUTHENTICATION, payload: success };
};

const successLogin = (token) => {
  localStorage.setItem(TOKEN_NAME, token);
  return { type: GETSUCCESSAUTHENTICATION };
};

export const fetchProfile = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: AUTHENTICATION_RESET });
      dispatch({ type: GETLOADINGPROFILE });
      const {
        data: { user },
      } = await apiGetProfile();
      dispatch({ type: GETSUCCESSPROFILE, payload: user });
    } catch (e) {
      if (e?.response?.data?.removeToken) {
        localStorage.removeItem(TOKEN_NAME);
      }
      dispatch({ type: GETFAILEDPROFILE, payload: HandelMyError(e) });
    }
  };
};
export const fetchStore = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GETLOADINGPSTORE });
      const {
        data: { store },
      } = await apiGetStore();
      dispatch({ type: GETSUCCESSSTORE, payload: store });
    } catch (e) {
      dispatch({ type: GETFAILEDSTORE, payload: HandelMyError(e) });
    }
  };
};

export const checkDomainIsReady = (domain) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CHECKDOMAINISREADY_REATTEMPT });
      dispatch({ type: CHECKDOMAINISREADY_LOADING });
      await apiCheckDomainIsReady(domain);
      dispatch({ type: CHECKDOMAINISREADY_SUCCESS });
    } catch (e) {
      dispatch({ type: CHECKDOMAINISREADY_FAILED });
    }
  };
};

export const addStore = (sendData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SAVELOADINGPSTORE });
      const { data } = await apiAddStore(sendData);
      dispatch({ type: SAVESUCCESSSTORE, payload: data });
    } catch (e) {
      dispatch({ type: SAVEFAILEDSTORE, payload: HandelMyError(e) });
    }
  };
};

export const saveAffiliator = (sendData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SAVELOADINGPAFFILITOR });
      const { data } = await apiSaveAffiliator(sendData);
      dispatch({ type: SAVESUCCESSAFFILITOR, payload: data });
    } catch (e) {
      dispatch({ type: SAVEFAILEDAFFILITOR, payload: HandelMyError(e) });
    }
  };
};

export const resetDomainChecker = (sendData) => {
  return async (dispatch) => {
    dispatch({ type: RESET_CHECKDOMAINLOADINGPSTORE });
  };
};

export const confirmDomainInStore = (sendData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CONFIRMDOMAINLOADINGPSTORE });
      const { data } = await apiConfirmDomainStore(sendData);
      dispatch({ type: CONFIRMDOMAINSUCCESSSTORE, payload: data });
    } catch (e) {
      dispatch({ type: CONFIRMDOMAINFAILEDSTORE, payload: HandelMyError(e) });
    }
  };
};

export const checkDomain = (sendData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CHECKDOMAINLOADINGPSTORE });
      const { data } = await apiCheckDomainStore(sendData);
      dispatch({ type: CHECKDOMAINSUCCESSSTORE, payload: data });
    } catch (e) {
      dispatch({ type: CHECKDOMAINFAILEDSTORE, payload: HandelMyError(e) });
    }
  };
};

export const updateSettings = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGSETTINGS });
      const {
        data: { success },
      } = await apiupdateSettings(data);
      dispatch({ type: UPDATESUCCESSSETTINGS, payload: success });
      const {
        data: { store },
      } = await apiGetStore();
      dispatch({ type: GETSUCCESSSTORE, payload: store });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDSETTINGS, payload: HandelMyError(e) });
    }
  };
};

export const pushAbousUsSocialLinks = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGSETTINGS });
      const {
        data: { success },
      } = await apipushAbousUsSocialLinks(data);
      dispatch({ type: UPDATESUCCESSSETTINGS, payload: success });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDSETTINGS, payload: HandelMyError(e) });
    }
    dispatch(fetchStore());
  };
};
export const removeAbousUsSocialLinks = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGSETTINGS });
      const {
        data: { success },
      } = await apiRemoveAbousUsSocialLinks(id);
      dispatch({ type: UPDATESUCCESSSETTINGS, payload: success });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDSETTINGS, payload: HandelMyError(e) });
    }
    dispatch(fetchStore());
  };
};

export const pushCities = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGSETTINGS });
      const {
        data: { success },
      } = await apipushCities(data);
      dispatch({ type: UPDATESUCCESSSETTINGS, payload: success });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDSETTINGS, payload: HandelMyError(e) });
    }
    try {
      dispatch({ type: GETLOADINGPSTORE });
      const {
        data: { store },
      } = await apiGetStore();
      dispatch({ type: GETSUCCESSSTORE, payload: store });
    } catch (e) {
      dispatch({ type: GETFAILEDSTORE, payload: HandelMyError(e) });
    }
  };
};

export const updatePushCities = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGSETTINGS });
      const {
        data: { success },
      } = await apiUpdatePushCitiesCities(id, data);
      dispatch({ type: UPDATESUCCESSSETTINGS, payload: success });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDSETTINGS, payload: HandelMyError(e) });
    }
    try {
      dispatch({ type: GETLOADINGPSTORE });
      const {
        data: { store },
      } = await apiGetStore();
      dispatch({ type: GETSUCCESSSTORE, payload: store });
    } catch (e) {
      dispatch({ type: GETFAILEDSTORE, payload: HandelMyError(e) });
    }
  };
};

export const removeCities = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGSETTINGS });
      const {
        data: { success },
      } = await apiRemoveCities(id);
      dispatch({ type: UPDATESUCCESSSETTINGS, payload: success });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDSETTINGS, payload: HandelMyError(e) });
    }
    try {
      dispatch({ type: GETLOADINGPSTORE });
      const {
        data: { store },
      } = await apiGetStore();
      dispatch({ type: GETSUCCESSSTORE, payload: store });
    } catch (e) {
      dispatch({ type: GETFAILEDSTORE, payload: HandelMyError(e) });
    }
  };
};

export const getAllSectionInSettings = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GETALLLOADINGSECTIONSETTINGS });
      const {
        data: { sections, sectionsUsed },
      } = await apiGetAllSectionInSettings();
      dispatch({
        type: GETALLSUCCESSSECTIONSETTINGS,
        payload: { sections, sectionsUsed },
      });
    } catch (e) {
      dispatch({
        type: GETALLFAILEDSECTIONSETTINGS,
        payload: HandelMyError(e),
      });
    }
  };
};

export const pushSections = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGSECTIONSETTING });
      const {
        data: { success },
      } = await apipushSections(data);
      dispatch({ type: UPDATESUCCESSSECTIONSETTING, payload: success });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDSECTIONSETTING, payload: HandelMyError(e) });
    }
    dispatch(fetchStore());
  };
};

export const pushCondition = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGCONDTIONSETTING });
      const {
        data: { success },
      } = await apiPushCondition(data);
      dispatch({ type: UPDATESUCCESSCONDTIONSETTING, payload: success });
    } catch (e) {
      dispatch({
        type: UPDATEFAILEDCONDTIONSETTING,
        payload: HandelMyError(e),
      });
    }
    dispatch(fetchStore());
  };
};

export const removeCondition = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGCONDTIONSETTING });
      const {
        data: { success },
      } = await apiRemoveCondition(id);
      dispatch({ type: UPDATESUCCESSCONDTIONSETTING, payload: success });
    } catch (e) {
      dispatch({
        type: UPDATEFAILEDCONDTIONSETTING,
        payload: HandelMyError(e),
      });
    }
    dispatch(fetchStore());
  };
};

export const updatePushSections = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGSECTIONSETTING });
      const {
        data: { success },
      } = await apiUpdatePushSections(id, data);
      dispatch({ type: UPDATESUCCESSSECTIONSETTING, payload: success });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDSECTIONSETTING, payload: HandelMyError(e) });
    }

    try {
      dispatch({ type: GETLOADINGPSTORE });
      const {
        data: { store },
      } = await apiGetStore();
      dispatch({ type: GETSUCCESSSTORE, payload: store });
    } catch (e) {
      dispatch({ type: GETFAILEDSTORE, payload: HandelMyError(e) });
    }
  };
};

export const removeSections = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATELOADINGSECTIONSETTING });
      const {
        data: { success },
      } = await apiRemoveSections(id);
      dispatch({ type: UPDATESUCCESSSECTIONSETTING, payload: success });
    } catch (e) {
      dispatch({ type: UPDATEFAILEDSECTIONSETTING, payload: HandelMyError(e) });
    }
    try {
      dispatch({ type: GETALLLOADINGSECTIONSETTINGS });
      const {
        data: { sections, sectionsUsed },
      } = await apiGetAllSectionInSettings();
      dispatch({
        type: GETALLSUCCESSSECTIONSETTINGS,
        payload: { sections, sectionsUsed },
      });
    } catch (e) {
      dispatch({
        type: GETALLFAILEDSECTIONSETTINGS,
        payload: HandelMyError(e),
      });
    }
    try {
      dispatch({ type: GETLOADINGPSTORE });
      const {
        data: { store },
      } = await apiGetStore();
      dispatch({ type: GETSUCCESSSTORE, payload: store });
    } catch (e) {
      dispatch({ type: GETFAILEDSTORE, payload: HandelMyError(e) });
    }
  };
};

const INITIAL_STATE = {
  getLoadingProfile: false,
  profile: {},
  getFailedProfile: null,

  getLoadingStore: false,
  store: {},
  getFailedStore: null,

  getUserTree_Loading: false,
  getUserTree_Success: null,
  getUserTree_Failed: null,

  storeImages_Loading: false,
  storeImages_Success: null,
  storeImages_Failed: null,

  sendOTP_Loading: false,
  sendOTP_Success: null,
  sendOTP_Failed: null,

  checkDomainIsReady_ReAttempt: false,
  checkDomainIsReady_Loading: false,
  checkDomainIsReady_Success: false,
  checkDomainIsReady_Failed: false,

  checkDomainLoadingStore: false,
  checkDomainSuccessStore: null,
  checkDomainFailedStore: null,

  confirmDomainLoadingStore: false,
  confirmDomainSuccessStore: null,
  confirmDomainFailedStore: null,

  saveLoadingStore: false,
  saveSuccessStore: null,
  saveFailedStore: null,

  updateConfirmationStore_loading: false,
  updateConfirmationStore_success: null,
  updateConfirmationStore_failed: null,

  getAllLoadingSectionSettings: false,
  getAllSuccessSectionSettings: null,
  getAllFailedSectionSettings: null,

  updateLoadingSettings: false,
  updateSuccessSettings: null,
  updateFailedSettings: null,

  updateLoadingSectionSetting: false,
  updateSuccessSectionSetting: null,
  updateFailedSectionSetting: null,

  updateLoadingCondtionSetting: false,
  updateSuccessCondtionSetting: null,
  updateFailedCondtionSetting: null,

  getLoadingAuthentication: false,
  isAuth: null,
  getFailedAuthentication: null,

  saveLoadingAuthentication: false,
  saveSuccessAuthentication: null,
  saveFailedAuthentication: null,

  updateLoadingAuthentication: false,
  updateSuccessAuthentication: null,
  updateFailedAuthentication: null,

  deleteLoadingAuthentication: false,
  deleteSuccessAuthentication: null,
  deleteFailedAuthentication: null,

  saveLoadingAffilitor: false,
  saveSuccessAffilitor: null,
  saveFailedAffilitor: null,

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Update confirmation store
    case UPDATECONFRIMATIONLOADINGPSTORE:
      return {
        ...state,
        updateConfirmationStore_loading: true,
        updateConfirmationStore_success: null,
        updateConfirmationStore_failed: null,
      };
    case UPDATECONFRIMATIONSUCCESSSTORE:
      return {
        ...state,
        updateConfirmationStore_loading: false,
        updateConfirmationStore_success: action.payload || "",
        updateConfirmationStore_failed: null,
      };
    case UPDATECONFRIMATIONFAILEDSTORE:
      return {
        ...state,
        updateConfirmationStore_loading: false,
        updateConfirmationStore_success: null,
        updateConfirmationStore_failed: action.payload || "",
      };


    // AUTHENTICATION_RESET
    case AUTHENTICATION_RESET:
      return {
        ...state,
        saveLoadingAuthentication: false,
        saveSuccessAuthentication: null,
        saveFailedAuthentication: null,

        updateLoadingAuthentication: false,
        updateSuccessAuthentication: null,
        updateFailedAuthentication: null,
      };
    // RESET_CHECKDOMAINLOADINGPSTORE
    case RESET_CHECKDOMAINLOADINGPSTORE:
      return {
        ...state,
        checkDomainLoadingStore: false,
        checkDomainSuccessStore: null,
        checkDomainFailedStore: null,
      };
    // AUTHENTICATION_RESET
    case AUTHENTICATION_RESETALL:
      return {
        getLoadingProfile: false,
        profile: {},
        getFailedProfile: null,

        getLoadingStore: false,
        store: {},
        getFailedStore: null,


        getAllLoadingSectionSettings: false,
        getAllSuccessSectionSettings: null,
        getAllFailedSectionSettings: null,

        updateLoadingSettings: false,
        updateSuccessSettings: null,
        updateFailedSettings: null,

        updateLoadingSectionSetting: false,
        updateSuccessSectionSetting: null,
        updateFailedSectionSetting: null,

        updateLoadingCondtionSetting: false,
        updateSuccessCondtionSetting: null,
        updateFailedCondtionSetting: null,

        getLoadingAuthentication: false,
        isAuth: null,
        getFailedAuthentication: null,

        saveLoadingAuthentication: false,
        saveSuccessAuthentication: null,
        saveFailedAuthentication: null,

        updateLoadingAuthentication: false,
        updateSuccessAuthentication: null,
        updateFailedAuthentication: null,

        deleteLoadingAuthentication: false,
        deleteSuccessAuthentication: null,
        deleteFailedAuthentication: null,
      };
    // Logout
    case UserLoggedOut:
      return {
        ...state,
        getLoadingAuthentication: false,
        isAuth: null,
        getFailedAuthentication: null,
      };

    // Fetch Profile
    case GETLOADINGPROFILE:
      return {
        ...state,
        getLoadingProfile: true,
        profile: {},
        getFailedProfile: null,
      };
    case GETSUCCESSPROFILE:
      return {
        ...state,
        getLoadingProfile: false,
        profile: action.payload || "",
        getFailedProfile: null,
      };
    case GETFAILEDPROFILE:
      return {
        ...state,
        getLoadingProfile: false,
        profile: {},
        getFailedProfile: action.payload || "",
      };

    // Get User Tree
    case GETUSERTREE_LOADING:
      return {
        ...state,
        getUserTree_Loading: true,
        getUserTree_Success: null,
        getUserTree_Failed: null,
      };
    case GETUSERTREE_SUCCESS:
      return {
        ...state,
        getUserTree_Loading: false,
        getUserTree_Success: action.payload || "",
        getUserTree_Failed: null,
      };
    case GETUSERTREE_FAILED:
      return {
        ...state,
        getUserTree_Loading: false,
        getUserTree_Success: null,
        getUserTree_Failed: action.payload || "",
      };

    // Check domain is ready
    case CHECKDOMAINISREADY_REATTEMPT:
      return {
        ...state,
        checkDomainIsReady_ReAttempt: true,
        checkDomainIsReady_Failed: false,
      };
    case CHECKDOMAINISREADY_LOADING:
      return {
        ...state,
        checkDomainIsReady_Loading: true,
        checkDomainIsReady_Success: false,
        checkDomainIsReady_Failed: false,
      };
    case CHECKDOMAINISREADY_SUCCESS:
      return {
        ...state,
        checkDomainIsReady_Loading: false,
        checkDomainIsReady_Success: true,
        checkDomainIsReady_Failed: false,
      };
    case CHECKDOMAINISREADY_FAILED:
      return {
        ...state,
        checkDomainIsReady_ReAttempt: false,
        checkDomainIsReady_Failed: new Date(new Date().setSeconds(new Date().getSeconds() + 5)),
      };
    // Save Store
    case SAVELOADINGPSTORE:
      return {
        ...state,
        saveLoadingStore: true,
        saveSuccessStore: null,
        saveFailedStore: null,
      };
    case SAVESUCCESSSTORE:
      return {
        ...state,
        saveLoadingStore: false,
        saveSuccessStore: action.payload || "",
        saveFailedStore: null,
      };
    case SAVEFAILEDSTORE:
      return {
        ...state,
        saveLoadingStore: false,
        saveSuccessStore: null,
        saveFailedStore: action.payload || "",
      };
    // Save Affilitor
    case SAVELOADINGPAFFILITOR:
      return {
        ...state,
        saveLoadingAffilitor: true,
        saveSuccessAffilitor: null,
        saveFailedAffilitor: null,
      };
    case SAVESUCCESSAFFILITOR:
      return {
        ...state,
        saveLoadingAffilitor: false,
        saveSuccessAffilitor: action.payload || "",
        saveFailedAffilitor: null,
      };
    case SAVEFAILEDAFFILITOR:
      return {
        ...state,
        saveLoadingAffilitor: false,
        saveSuccessAffilitor: null,
        saveFailedAffilitor: action.payload || "",
      };
    // Check Subdomain
    case CHECKDOMAINLOADINGPSTORE:
      return {
        ...state,
        checkDomainLoadingStore: true,
        checkDomainSuccessStore: null,
        checkDomainFailedStore: null,
      };
    case CHECKDOMAINSUCCESSSTORE:
      return {
        ...state,
        checkDomainLoadingStore: false,
        checkDomainSuccessStore: action.payload || "",
        checkDomainFailedStore: null,
      };
    case CHECKDOMAINFAILEDSTORE:
      return {
        ...state,
        checkDomainLoadingStore: false,
        checkDomainSuccessStore: null,
        checkDomainFailedStore: action.payload || "",
      };
    // Send OTP
    case SENDOTP_LOADING:
      return {
        ...state,
        sendOTP_Loading: true,
        sendOTP_Success: null,
        sendOTP_Failed: null,
      };
    case SENDOTP_SUCCESS:
      return {
        ...state,
        sendOTP_Loading: false,
        sendOTP_Success: action.payload || "",
        sendOTP_Failed: null,
      };
    case SENDOTP_FAILED:
      return {
        ...state,
        sendOTP_Loading: false,
        sendOTP_Success: null,
        sendOTP_Failed: action.payload || "",
      };
    // Confirm Subdomain
    case CONFIRMDOMAINLOADINGPSTORE:
      return {
        ...state,
        confirmDomainLoadingStore: true,
        confirmDomainSuccessStore: null,
        confirmDomainFailedStore: null,
      };
    case CONFIRMDOMAINSUCCESSSTORE:
      return {
        ...state,
        confirmDomainLoadingStore: false,
        confirmDomainSuccessStore: action.payload || "",
        confirmDomainFailedStore: null,
      };
    case CONFIRMDOMAINFAILEDSTORE:
      return {
        ...state,
        confirmDomainLoadingStore: false,
        confirmDomainSuccessStore: null,
        confirmDomainFailedStore: action.payload || "",
      };
    // Fetch Settings
    case GETALLLOADINGSECTIONSETTINGS:
      return {
        ...state,
        getAllLoadingSectionSettings: true,
        getAllSuccessSectionSettings: null,
        getAllFailedSectionSettings: null,
      };
    case GETALLSUCCESSSECTIONSETTINGS:
      return {
        ...state,
        getAllLoadingSectionSettings: false,
        getAllSuccessSectionSettings: action.payload || {},
        getAllFailedSectionSettings: null,
      };
    case GETALLFAILEDSECTIONSETTINGS:
      return {
        ...state,
        getAllLoadingSectionSettings: false,
        getAllSuccessSectionSettings: null,
        getAllFailedSectionSettings: action.payload || "",
      };
    case STOREIMAGES_LOADING:
      return {
        ...state,
        storeImages_Loading: true,
        storeImages_Success: null,
        storeImages_Failed: null,
      };
    case STOREIMAGES_SUCCESS:
      return {
        ...state,
        storeImages_Loading: false,
        storeImages_Success: action.payload || {},
        storeImages_Failed: null,
      };
    case STOREIMAGES_FAILED:
      return {
        ...state,
        storeImages_Loading: false,
        storeImages_Success: null,
        storeImages_Failed: action.payload || "",
      };

    // Sections
    case UPDATELOADINGSECTIONSETTING:
      return {
        ...state,
        updateLoadingSectionSetting: true,
        updateSuccessSectionSetting: null,
        updateFailedSectionSetting: null,
      };
    case UPDATESUCCESSSECTIONSETTING:
      return {
        ...state,
        updateLoadingSectionSetting: false,
        updateSuccessSectionSetting: action.payload || "",
        updateFailedSectionSetting: null,
      };
    case UPDATEFAILEDSECTIONSETTING:
      return {
        ...state,
        updateLoadingSectionSetting: false,
        updateSuccessSectionSetting: null,
        updateFailedSectionSetting: action.payload || "",
      };

    // Condtions
    case UPDATELOADINGCONDTIONSETTING:
      return {
        ...state,
        updateLoadingCondtionSetting: true,
        updateSuccessCondtionSetting: null,
        updateFailedCondtionSetting: null,
      };
    case UPDATESUCCESSCONDTIONSETTING:
      return {
        ...state,
        updateLoadingCondtionSetting: false,
        updateSuccessCondtionSetting: action.payload || "",
        updateFailedCondtionSetting: null,
      };
    case UPDATEFAILEDCONDTIONSETTING:
      return {
        ...state,
        updateLoadingCondtionSetting: false,
        updateSuccessCondtionSetting: null,
        updateFailedCondtionSetting: action.payload || "",
      };

    // Fetch Store
    case GETLOADINGPSTORE:
      return {
        ...state,
        getLoadingStore: true,
        store: {},
        getFailedStore: null,
      };
    case GETSUCCESSSTORE:
      return {
        ...state,
        getLoadingStore: false,
        store: action.payload || "",
        getFailedStore: null,
      };
    case GETFAILEDSTORE:
      return {
        ...state,
        getLoadingStore: false,
        store: {},
        getFailedStore: action.payload || "",
      };
    // Update Store
    case UPDATELOADINGSETTINGS:
      return {
        ...state,
        updateLoadingSettings: true,
        updateSuccessSettings: null,
        updateFailedSettings: null,
      };
    case UPDATESUCCESSSETTINGS:
      return {
        ...state,
        updateLoadingSettings: false,
        updateSuccessSettings: action.payload || "",
        updateFailedSettings: null,
      };
    case UPDATEFAILEDSETTINGS:
      return {
        ...state,
        updateLoadingSettings: false,
        updateSuccessSettings: null,
        updateFailedSettings: action.payload || "",
      };

    // lOGIN
    case GETLOADINGAUTHENTICATION:
      return {
        ...state,
        getLoadingAuthentication: true,
        isAuth: null,
        getFailedAuthentication: null,
      };
    case GETSUCCESSAUTHENTICATION:
      return {
        ...state,
        getLoadingAuthentication: false,
        isAuth: true,
        getFailedAuthentication: null,
      };
    case GETFAILEDAUTHENTICATION:
      return {
        ...state,
        getLoadingAuthentication: false,
        isAuth: false,
        getFailedAuthentication: action.payload || "",
      };
    // Register
    case SAVELOADINGAUTHENTICATION:
      return {
        ...state,
        saveLoadingAuthentication: true,
        saveSuccessAuthentication: null,
        saveFailedAuthentication: null,
      };
    case SAVESUCCESSAUTHENTICATION:
      return {
        ...state,
        saveLoadingAuthentication: false,
        saveSuccessAuthentication: action.payload || "",
        saveFailedAuthentication: null,
      };
    case SAVEFAILEDAUTHENTICATION:
      return {
        ...state,
        saveLoadingAuthentication: false,
        saveSuccessAuthentication: null,
        saveFailedAuthentication: action.payload || "",
      };

    case UPDATELOADINGAUTHENTICATION:
      return {
        ...state,
        updateLoadingAuthentication: true,
        updateSuccessAuthentication: null,
        updateFailedAuthentication: null,
      };
    case UPDATESUCCESSAUTHENTICATION:
      return {
        ...state,
        updateLoadingAuthentication: false,
        updateSuccessAuthentication: action.payload || "",
        updateFailedAuthentication: null,
      };
    case UPDATEFAILEDAUTHENTICATION:
      return {
        ...state,
        updateLoadingAuthentication: false,
        updateSuccessAuthentication: null,
        updateFailedAuthentication: action.payload || "",
      };

    case DELETELOADINGAUTHENTICATION:
      return {
        ...state,
        deleteLoadingAuthentication: true,
        deleteSuccessAuthentication: null,
        deleteFailedAuthentication: null,
      };
    case DELETESUCCESSAUTHENTICATION:
      return {
        ...state,
        deleteLoadingAuthentication: false,
        deleteSuccessAuthentication: action.payload || "",
        deleteFailedAuthentication: null,
      };
    case DELETEFAILEDAUTHENTICATION:
      return {
        ...state,
        deleteLoadingAuthentication: false,
        deleteSuccessAuthentication: null,
        deleteFailedAuthentication: action.payload || "",
      };

    default:
      return state;
  }
};
