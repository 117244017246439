import React from "react";
import HomeFooter from "../components/homeFooter";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { Container } from "reactstrap";
import particles3 from "../assets/images/hero-bg/particles-3.svg";
import LandPageHeader from "../components/LandPageHeader";

const CustomContainer = ({ Children }) => {
  return (
    <>
      <LandPageHeader />
      <div className="hero-wrapper bg-composed-wrapper bg-second ltr">
        <div className="divider bg-white-5" />
        <div className="hero-wrapper--content">
          <div
            className="bg-composed-wrapper--image bg-composed-filter-rm"
            style={{
              backgroundImage:
                "url(https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhOPQ9xmMji1F_yjH05mNJuE0AKNJY4gnErgMwdvvSJQpwha0xHjiPCOOX5Nx8-u7xPXj9E_MRC29vHHgBanIILkOJt-WqIiZzGhXXJjNhsrJB3QGX0FkkCcLDp9z0wvIsk52wkileVlimwOxwaxqAhK0AyHGVmPyLPyn-un-1bbf98MS7MHCpVQZr_/s16000/affilig-bg-hijab.jpg)",
            }}
          />
          <div className="bg-composed-wrapper--image opacity-6" style={{ backgroundImage: "url(" + particles3 + ")" }} />
          <div className="bg-composed-wrapper--bg bg-second opacity-4" />
          <div className="bg-composed-wrapper--content">
            <Container className="z-over text-white py-0 py-5 mt-5">{Children}</Container>
            <div className="shape-container-top-1 w-100">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path
                  fill="var(--second)"
                  fillOpacity="1"
                  d="M0,96L48,112C96,128,192,160,288,176C384,192,480,192,576,202.7C672,213,768,235,864,213.3C960,192,1056,128,1152,133.3C1248,139,1344,213,1392,250.7L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
      <MessengerCustomerChat pageId="101256088552596" appId="469150520779127" />
    </>
  );
};

export default CustomContainer;
