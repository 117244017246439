import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { Container, Card } from "reactstrap";
import { ProtectedRoute } from "./components";
import "./assets/index.scss";
import { Helmet } from "react-helmet";
import { Route } from "react-router-dom";
import CreateStore from "./pages/ResumeCreateStore";
import CreateAffiliator from "./pages/CreateAffiliator";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Pricing from "./pages/Pricing";
import NotFound from "./pages/NotFound";

const PlatformLogin = React.lazy(() => import("./pages/PlatformLogin"));
const PlatformHome = React.lazy(() => import("./pages/PlatformHome"));
const LoadingComponent = () => (
  <Container className="App-header d-flex align-items-center justify-content-center">
    <Card className="rounded-sm card-box shadow-none p-3 m-3 align-items-center">
      <div className="d-flex align-items-center justify-content-center align-self-center" style={{ width: "150px", height: "80px" }}>
        <HashLoader color={"#3c44b1"} loading={true} />
      </div>
      <p className="mb-0 pt-3 text-black-50 text-center">جاري التحميل</p>
    </Card>
  </Container>
);
function App(props) {
  return (
    <div
      className="position-relative"
      id="target"
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        width: "100%",
        height: "100%",
      }}
    >
      <Suspense fallback={<LoadingComponent />}>
        {/* Platform */}
        <Container fluid="lg" className="rtl themed-container" style={{ zIndex: 2, position: "relative" }}>
          <Switch>
            <ProtectedRoute path="/secureLogin" exact component={PlatformLogin} />
            <ProtectedRoute path="/secureHome" exact component={PlatformHome} />
          </Switch>
        </Container>
      </Suspense>
      <Switch>
        {/* Affilitor */}
        <Route exact path="/affiliator">
          <Helmet>
            <title>منصة Affilih للمسوقين بالعمولة</title>
            <meta name="title" content="منصة Affilih للمسوقين بالعمولة" />
            <meta property="og:title" content="منصة Affilih للمسوقين بالعمولة" />
            <meta property="twitter:title" content="منصة Affilih للمسوقين بالعمولة" />

            {/* Description */}
            <meta
              name="description"
              content="منصة Affilih للمسوقين بالعمولة - منصة مصممة لاستعراض أفضل أنظمة التسويق بالعمولة وكذلك لإنشاء المتاجر الإلكترونية."
            />
            <meta
              property="og:description"
              content="منصة Affilih للمسوقين بالعمولة - منصة مصممة لاستعراض أفضل أنظمة التسويق بالعمولة وكذلك لإنشاء المتاجر الإلكترونية."
            />
            <meta
              property="twitter:description"
              content="منصة Affilih للمسوقين بالعمولة - منصة مصممة لاستعراض أفضل أنظمة التسويق بالعمولة وكذلك لإنشاء المتاجر الإلكترونية."
            />
          </Helmet>
          <CreateAffiliator />
        </Route>

        {/* AboutUs */}
        <Route exact path="/about-us">
          <Helmet>
            <title>معلومات عنا</title>
            <meta name="title" content="معلومات عنا" />
            <meta property="og:title" content="معلومات عنا" />
            <meta property="twitter:title" content="معلومات عنا" />
          </Helmet>
          <AboutUs />
        </Route>

        {/* Pricing */}
        <Route exact path="/pricing">
          <Helmet>
            <title>الاسعار</title>
            <meta name="title" content="الاسعار" />
            <meta property="og:title" content="الاسعار" />
            <meta property="twitter:title" content="الاسعار" />
          </Helmet>
          <Pricing />
        </Route>

        {/* ContactUs */}
        <Route exact path="/contact-us">
          <Helmet>
            <title>التواصل معنا</title>
            <meta name="title" content="التواصل معنا" />
            <meta property="og:title" content="التواصل معنا" />
            <meta property="twitter:title" content="التواصل معنا" />
          </Helmet>
          <ContactUs />
        </Route>

        {/* PrivacyPolicy */}
        <Route exact path="/privacy-policy">
          <Helmet>
            <title>الخصوصية وسريّة المعلومات</title>
            <meta name="title" content="الخصوصية وسريّة المعلومات" />
            <meta property="og:title" content="الخصوصية وسريّة المعلومات" />
            <meta property="twitter:title" content="الخصوصية وسريّة المعلومات" />
          </Helmet>
          <PrivacyPolicy />
        </Route>

        {/* Terms and Condition */}
        <Route
          exact
          path="/terms-and-condition"
          render={() => {
            window.location.href = "terms-and-condition.html";
          }}
        />

        {/* Trader */}
        <Route exact path="/">
          <Helmet>
            {/* Title */}
            <title>هل ترغب في إنشاء نظام التسويق بالعمولة؟ ابدأ الآن مع Affilih </title>
            <meta name="title" content="هل ترغب في إنشاء نظام التسويق بالعمولة؟ ابدأ الآن مع Affilih " />
            <meta property="og:title" content="هل ترغب في إنشاء نظام التسويق بالعمولة؟ ابدأ الآن مع Affilih " />
            <meta property="twitter:title" content="هل ترغب في إنشاء نظام التسويق بالعمولة؟ ابدأ الآن مع Affilih " />
            {/* Description */}
            <meta
              name="description"
              content="منصة Affilih لإنشاء أنظمة التسويق بالعمولة بضغطة واحدة لإدارة المسوقين والتجار مندوبين الشحن مربوط بدومين شركتك مع دعم فني علي مدار الـ24 ساعة."
            />
            <meta
              property="og:description"
              content="منصة Affilih لإنشاء أنظمة التسويق بالعمولة بضغطة واحدة لإدارة المسوقين والتجار مندوبين الشحن مربوط بدومين شركتك مع دعم فني علي مدار الـ24 ساعة."
            />
            <meta
              property="twitter:description"
              content="منصة Affilih لإنشاء أنظمة التسويق بالعمولة بضغطة واحدة لإدارة المسوقين والتجار مندوبين الشحن مربوط بدومين شركتك مع دعم فني علي مدار الـ24 ساعة."
            />
          </Helmet>
          <CreateStore />
        </Route>
        {/* Trader */}
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
