import React, { useEffect, useState } from "react";
import GetFailedComponent from "../components/GetFailed";
import CustomContainer from "../components/CustomContainer";

import { Row, Col, Button, Container, ListGroup, ListGroupItem } from "reactstrap";
import { connect } from "react-redux";
import { GoogleAPI, GoogleLogin } from "react-google-oauth";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Features from "../components/Features";
import queryString from "query-string";

import { FcGoogle } from "react-icons/fc";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { BsListNested, BsCalendar4Week, BsCheck2All } from "react-icons/bs";
import { FaPhone } from "react-icons/fa";
import { addStore, checkDomain, resetDomainChecker, checkDomainIsReady, saveAffiliator } from "../redux/config";
import logoWhite from "../assets/images/logo_white.svg";
import useGeoLocation from "react-ipgeolocation";
import PhoneInput from "react-phone-input-2";

import ar from "react-phone-input-2/lang/ar.json";
import "react-phone-input-2/lib/style.css";

const ResumeCreateStoreComponent = (props) => {
  const {
    saveLoadingStore,
    saveSuccessStore,
    saveFailedStore,

    addStore,
  } = props;
  const getAccessToken = (e) => {
    if (e[Object.keys(e)[1]]?.access_token !== undefined) {
      return e[Object.keys(e)[1]]?.access_token;
    }
    if (e[Object.keys(e)[2]]?.access_token !== undefined) {
      return e[Object.keys(e)[2]]?.access_token;
    }
    if (e[Object.keys(e)[0]]?.access_token !== undefined) {
      return e[Object.keys(e)[0]]?.access_token;
    }
    return null;
  };

  // let cApi = useGeoLocation().country;

  useEffect(() => {
    if (saveSuccessStore !== null) {
      window.location.href = saveSuccessStore?.redirectURL;
    }
  }, [saveSuccessStore]);
  // useEffect(() => {
  //   fetch("https://api.ipregistry.co/?key=tryout")
  //     .then(function (response) {
  //       console.log(response.json());
  //       return response.json();
  //     })
  //     .then(function (payload) {
  //       console.log(payload.location.country.name + ", " + payload.location.city);
  //     });
  // }, []);

  useEffect(() => {
    let windowQuery = queryString.parse(window.location.search);
    if ("refem" in windowQuery) {
      console.log(windowQuery.refem);
      // windowQuery.internalToken = undefined
      // delete windowQuery.internalToken
      // props.history.push({ query: windowQuery }, undefined, { shallow: true })
    }
  }, [props]);
  const [userPhone, setUserPhone] = useState("");
  const [isOpen, setOpenStatus] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (userPhone.length > 9) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [userPhone]);

  let cApi = useGeoLocation()?.country?.toLowerCase() || "eg";

  return (
    <>
      <CustomContainer
        Children={
          <Row className="py-5 rtl">
            <Col lg="7">
              <div className="text-center text-lg-left py-0 pr-0 pr-xl-3 py-xl-5 w-100">
                <div className="pr-0 pl-xl-5 text-white rtl w-100 d-flex flex-column justify-content-center justify-content-md-start">
                  <div className="font-size-sm d-inline-flex bg-white-10 px-4 rounded-lg text-white py-2 text-uppercase mt-3 mx-auto mr-md-0 ml-md-auto">
                    <img alt="Affilih Logo" className="mb-2" style={{ width: "8em" }} src={logoWhite} />
                  </div>
                  <h1 className="font-weight-bold mt-4 text-uppercase customH1 text-center text-md-right">لحلول التسويق بالعمولة</h1>
                  <p className="font-weight-bold font-size-xl m-0 pb-3 mb-5 text-center text-md-right text-first opacity-7">
                    أنشئ نظام تسويق بالعمولة بضغطة واحدة لإدارة المسوقين
                  </p>
                  <div className="d-inline-flex flex-column w-100 mt-5">
                    {(() => {
                      if (isOpen) {
                        if (saveLoadingStore) {
                          return (
                            <SkeletonTheme
                              color="#1D1F2D"
                              highlightColor="#fff"
                              className="d-flex align-items-center flex-row flex-wrap w-100 px-0 justify-content-between opacity-6"
                            >
                              <div className="d-flex align-items-center flex-row flex-wrap w-100 ">
                                <p className="col-12 px-0">
                                  <Skeleton height={45} className="w-100 opacity-6" duration={2} />
                                </p>
                              </div>
                            </SkeletonTheme>
                          );
                        } else {
                          return (
                            <>
                              {(() => {
                                if (saveFailedStore !== null) {
                                  return (
                                    <Container className="text-warning">
                                      <GetFailedComponent failed={saveFailedStore} />
                                    </Container>
                                  );
                                }
                              })()}
                              <Row className="w-100 mt-4 mx-0 justify-content-center flex-column">
                                <Col sm="12" className="px-sm-0 d-flex align-items-center">
                                  {(() => {
                                    if (cApi !== undefined) {
                                      return (
                                        <PhoneInput
                                          specialLabel={false}
                                          enableAreaCodeStretch
                                          enableSearch={false}
                                          disableDropdown={true}
                                          disableSearchIcon={true}
                                          country={cApi}
                                          countryCodeEditable={true}
                                          value={userPhone}
                                          onChange={(value, data, event, formattedValue) => {
                                            setUserPhone(value);
                                          }}
                                          localization={ar}
                                          id="userPhone"
                                          inputClass="pl-5 mb-3 mb-md-0 w-100 bg-white border-0 text-second d-flex align-items-center py-4 rounded font-weight-bold font-size-xl"
                                          buttonClass="bg-white border-0 rounded pl-2 "
                                          // className="d-flex align-items-center justify-content-center justify-content-md-start px-5 mt-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg ltr"

                                          containerClass="ltr text-dark w-100 rounded"
                                          type="phone"
                                          name="userPhone"
                                          autoComplete="tel"
                                          searchClass="w-100 d-flex justify-content-center m-auto rtl"
                                        />
                                      );
                                    }
                                  })()}
                                </Col>
                                <Col sm="12" className="px-sm-0 d-flex align-items-center mt-4">
                                  <GoogleAPI clientId="303396594421-ug339mkuhhvepqg4b8i487opunft66bv.apps.googleusercontent.com">
                                    <div
                                      style={{
                                        maxWidth: "100%",
                                        display: "inline-block",
                                      }}
                                      className="w-100 rounded overflow-hidden"
                                    >
                                      <div>
                                        <GoogleLogin
                                          disabled={!isValid}
                                          onLoginSuccess={(e) => {
                                            addStore({
                                              token: getAccessToken(e),
                                              userPhone,
                                            });
                                          }}
                                          backgroundColor="#fff"
                                          width="100%"
                                          text={
                                            <>
                                              {(() => {
                                                if (!isValid) {
                                                  return (
                                                    <>
                                                      <FaPhone className="ml-2 text-second" size="20px" />
                                                      <span className="font-weight-bold font-size-md text-second">أدخل هاتفك للإنـشـاء</span>
                                                    </>
                                                  );
                                                } else {
                                                  return (
                                                    <>
                                                      <FcGoogle className="ml-2" size="30px" />
                                                      <span className="font-weight-bold font-size-xl text-second">إنـشـاء</span>
                                                    </>
                                                  );
                                                }
                                              })()}
                                            </>
                                          }
                                        />
                                      </div>
                                    </div>
                                  </GoogleAPI>
                                </Col>
                                <Col className="mt-2">
                                  {/* <div className="d-flex align-items-center flex-row"> */}
                                  <span className="font-weight-bold font-size-sm">
                                    <BsCheck2All className="ml-1" />
                                    من خلال إنشاء النظام ، فإنك توافق على جميع الشروط والأحكام
                                  </span>
                                  <Button
                                    target="_blank"
                                    href={`/terms-and-condition.html`}
                                    color="link"
                                    className="btn-link-first px-0 font-weight-bold font-size-sm mr-1"
                                    size="sm"
                                  >
                                    <span>عرض الشروط والأحكام</span>
                                  </Button>
                                  {/* </div> */}
                                </Col>
                              </Row>
                            </>
                          );
                        }
                      } else {
                        return (
                          <Button
                            color="first"
                            onClick={(e) => setOpenStatus(true)}
                            className="d-flex align-items-center justify-content-center justify-content-md-start px-5 mt-5 font-size-sm font-weight-bold btn-animated-icon text-uppercase rounded shadow-none py-3 hover-scale-sm hover-scale-lg ltr"
                          >
                            <span className="btn-wrapper--label font-weight-bold font-size-xxl mb-0 mt-1">إنشاء</span>
                            <span className="btn-wrapper--icon">
                              <AiOutlineAppstoreAdd size={25} />
                            </span>
                          </Button>
                        );
                      }
                    })()}
                    <div className="d-flex pt-4">
                      <Button
                        tag="a"
                        href="https://calendly.com/affilih/meet"
                        target="_blank"
                        color="neutral-first"
                        rel="noopener"
                        size="xl"
                        className="w-100 ellipsis-text my-2 my-md-0 mt-md-0 ml-2 rtl rounded d-flex align-items-center justify-content-center font-weight-bold mb-0"
                      >
                        <BsCalendar4Week size="18px" className="ml-1 mb-1" />
                        رتب موعدًا
                      </Button>
                      <Button
                        tag="a"
                        href="https://affiliator.affilih.com"
                        target="_blank"
                        color="neutral-first"
                        size="xl"
                        className="w-100 ellipsis-text my-2 my-md-0 mt-md-0 rtl rounded d-flex align-items-center justify-content-center font-weight-bold mb-0"
                      >
                        <BsListNested size="18px" className="ml-1 mb-1" />
                        تصفح الأنظمة
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="5" className="d-flex align-items-center mt-5 mt-md-0 flex-column justify-content-start rtl">
              <div className="bg-transparent mx-0 mb-5 mt-5 object-skew hover-scale-sm w-100 border-0">
                <ListGroup flush className="pr-0 mt-0 mt-md-5">
                  {Features("merchants")?.map((feature, i) => (
                    <ListGroupItem
                      key={i}
                      tag="a"
                      action
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                      className="d-flex align-items-center py-3 bg-transparent"
                    >
                      <div className="d-flex align-items-center">
                        <div className=" ml-3 d-50">
                          <span className="font-weight-bold font-size-xxl text-first">#{i + 1}</span>
                        </div>
                        <div>
                          <div className="font-weight-bold font-size-xl text-white text-right">{feature?.title}</div>
                        </div>
                      </div>
                      <div className="mr-auto font-weight-bold font-size-xxl text-white-50">{feature?.icon}</div>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </div>
            </Col>
          </Row>
        }
      />
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    saveLoadingStore: auth.saveLoadingStore,
    saveSuccessStore: auth.saveSuccessStore,
    saveFailedStore: auth.saveFailedStore,

    checkDomainLoadingStore: auth.checkDomainLoadingStore,
    checkDomainSuccessStore: auth.checkDomainSuccessStore,
    checkDomainFailedStore: auth.checkDomainFailedStore,

    checkDomainIsReady_ReAttempt: auth.checkDomainIsReady_ReAttempt,
    checkDomainIsReady_Loading: auth.checkDomainIsReady_Loading,
    checkDomainIsReady_Success: auth.checkDomainIsReady_Success,
    checkDomainIsReady_Failed: auth.checkDomainIsReady_Failed,
  };
};

const ResumeCreateStore = connect(mapStateToProps, {
  addStore,
  saveAffiliator,
  checkDomain,
  resetDomainChecker,
  checkDomainIsReady,
})(ResumeCreateStoreComponent);
export default ResumeCreateStore;
