import React from "react";
import { Row, Col, Button } from "reactstrap";
import CustomContainer from "../components/CustomContainer";
import logoWhite from "../assets/images/logo_white.svg";
import { AiOutlineNumber, AiFillPhone } from "react-icons/ai";
import { BsMessenger, BsCalendar4Week, BsWhatsapp } from "react-icons/bs";
import { MdMarkEmailUnread } from "react-icons/md";

const SectionStyle = ({ title, paragraph }) => (
  <section className="mt-5">
    <h4 className="display-4 font-weight-bold mb-3">
      <AiOutlineNumber className="ml-1" size="25px" /> {title}
    </h4>
    {paragraph && <p className="font-weight-bold font-size-xl m-0 pb-3 mb-5 text-right text-white opacity-7">{paragraph}</p>}
  </section>
);

const ContactUs = () => {
  return (
    <>
      <CustomContainer
        Children={
          <Row className="py-5 rtl">
            <Col lg="12">
              <div className="text-center text-lg-left py-0 pr-0 pr-xl-3 py-xl-5 w-100">
                <div className="pr-0 pl-xl-5 text-white rtl w-100 d-flex flex-column justify-content-center justify-content-md-start">
                  <div className="font-size-sm d-inline-flex bg-white-10 px-4 rounded-lg text-white py-2 text-uppercase mt-3 mx-auto mr-md-0 ml-md-auto">
                    <img alt="Affilih Logo" className="mb-2" style={{ width: "8em" }} src={logoWhite} />
                  </div>
                  <h1 className="font-weight-bold mt-4 text-uppercase customH1 text-center text-md-right">اتصل بنا</h1>
                  {/* Questions */}
                  <div className="w-100 mt-5" />
                  <SectionStyle title="قنوات التواصل" paragraph="" />
                  <div className="d-flex flex-wrap flex-row">
                    <Button
                      tag="a"
                      href="https://api.whatsapp.com/send?phone=201110918739&text=Hi+Affilih&type=phone_number&app_absent=0"
                      target="_blank"
                      color="neutral-first"
                      rel="noopener"
                      size="xl"
                      className="ellipsis-text my-2 my-md-0 mt-md-0 ml-2 rtl rounded font-weight-bold mb-0"
                    >
                      <BsWhatsapp size="18px" className="ml-1 mb-1" />
                      WhatsApp
                    </Button>
                    <Button
                      tag="a"
                      href="https://m.me/affilih"
                      target="_blank"
                      color="neutral-first"
                      rel="noopener"
                      size="xl"
                      className="ellipsis-text my-2 my-md-0 mt-md-0 ml-2 rtl rounded font-weight-bold mb-0"
                    >
                      <BsMessenger size="18px" className="ml-1 mb-1" />
                      Messenger
                    </Button>
                    <Button
                      tag="a"
                      href="https://calendly.com/affilih/meet"
                      target="_blank"
                      color="neutral-first"
                      rel="noopener"
                      size="xl"
                      className="ellipsis-text my-2 my-md-0 mt-md-0 rtl rounded font-weight-bold mb-0"
                    >
                      <BsCalendar4Week size="18px" className="ml-1 mb-1" />
                      رتب موعدًا
                    </Button>
                  </div>
                  <SectionStyle title="التواصل المباشر" paragraph="" />
                  <div className="d-flex flex-wrap flex-row">
                    <Button
                      tag="a"
                      href="tel:201110918739"
                      target="_blank"
                      color="neutral-first"
                      rel="noopener"
                      size="xl"
                      className="ellipsis-text my-2 my-md-0 mt-md-0 ml-2 rtl rounded font-weight-bold mb-0"
                    >
                      <AiFillPhone size="18px" className="ml-1 mb-1" />
                      201110918739
                    </Button>
                    <Button
                      tag="a"
                      href="mailto:support@affilih.com"
                      target="_blank"
                      color="neutral-first"
                      rel="noopener"
                      size="xl"
                      className="ellipsis-text my-2 my-md-0 mt-md-0 ml-2 rtl rounded font-weight-bold mb-0"
                    >
                      <MdMarkEmailUnread size="18px" className="ml-1 mb-1" />
                      support@affilih.com
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        }
      />
    </>
  );
};

export default ContactUs;
