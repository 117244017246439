import React, { useState } from "react";
import { Button, Collapse } from "reactstrap";
import clsx from "clsx";
import { RiUser6Line } from "react-icons/ri";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { ImPriceTags } from "react-icons/im";
import { IoMdCall } from "react-icons/io";
import { BsInfoLg } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { Link } from "react-router-dom";

export default function LandPageHeader() {
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);
  const URLSComponent = () => (
    <>
      {(() => {
        if (window.location.pathname === "/") {
          return (
            <li className="py-2 d-flex justify-content-end">
              <Link to="/affiliator" className="rounded font-weight-bolder bg-neutral-first text-first">
                <span className="mt-1">انضم كمسوق</span>
                <RiUser6Line size="20px" className="ml-1" />
              </Link>
            </li>
          );
        } else {
          return (
            <li className="py-2 d-flex justify-content-end">
              <Link to="/" className="rounded font-weight-bolder bg-neutral-first text-first">
                <span className="mt-1">إنشاء نظام</span>
                <AiOutlineAppstoreAdd size="20px" className="ml-1" />
              </Link>
            </li>
          );
        }
      })()}
      {/* <li className="py-2 d-flex justify-content-end">
        <Link
          to={{
            pathname: "https://www.youtube.com/watch?v=7DALylSeJG0&list=PLLPIku5jqgSjo0yoW2MIrskUr9Du5_4_W&index=1",
          }}
          target="_blank"
          rel="noopener"
          className="text-second rounded font-weight-bolder"
        >
          <span className="mt-1">الشروحات</span>
          <FiYoutube size="20px" className="ml-1" />
        </Link>
      </li> */}
      {/* <li className="py-2 d-flex justify-content-end">
        <Link to={{ pathname: "https://m.me/affilih" }} target="_blank" rel="noopener" className="text-second rounded font-weight-bolder">
          <span className="mt-1">الدعم</span>
          <BiSupport size="20px" className="ml-1" />
        </Link>
      </li>
       */}
      <li className="py-2 d-flex justify-content-end">
        <Link to="/pricing" className="text-first rounded font-weight-bolder">
          <span className="mt-1">الاسعار</span>
          <ImPriceTags size="20px" className="ml-1 text-first" />
        </Link>
      </li>
      <li className="py-2 d-flex justify-content-end">
        <Link to="/about-us" className="text-first rounded font-weight-bolder">
          <span className="mt-1">معلومات عنا</span>
          <BsInfoLg size="20px" className="ml-1" />
        </Link>
      </li>
      <li className="py-2 d-flex justify-content-end">
        <Link to="/contact-us" className="text-first rounded font-weight-bolder">
          <span className="mt-1">اتصل بنا</span>
          <IoMdCall size="20px" className="ml-1 text-first" />
        </Link>
      </li>
      <li className="py-2 d-flex justify-content-end">
        <div
          className="fb-like d-flex align-items-center"
          data-href="https://www.facebook.com/affilih"
          data-width=""
          data-layout="button_count"
          data-action="like"
          data-size="large"
          data-share="false"
        ></div>
      </li>
    </>
  );
  return (
    <>
      <header className="bg-composed-wrapper--content position-fixed" style={{ zIndex: "999" }}>
        <div className="header-nav-wrapper bg-white px-3 px-md-4 shadow-lg header-nav-wrapper-lg navbar-light">
          <div className="app-nav-logo">
            <Link to="/" className="app-nav-logo app-nav-logo--second text-second">
              <div className="app-nav-logo--text text-primary">
                <img
                  alt="Affilih"
                  className="d-flex mr-1"
                  style={{ width: "auto", height: "2em" }}
                  src="https://blogger.googleusercontent.com/img/a/AVvXsEgsGBW2np4eIndGJPbF8ZJpYWed8PxeGBQxqt-qDPMmoWrOvb9A2DM11CkPqpyL6v1NhOaQCw9M70nOo_SJzJCW2g_8Pure1_WHjw7qRpCcWhhDWBCFdQTyx1Gjx4aZCPzmsXUgwqAKl_ZuV73IApsjXpaJdWQq5B1Y64Kc9jetegDfz_cubnEBJija"
                />
              </div>
            </Link>
          </div>
          <div className="header-nav-menu d-none d-lg-block">
            <ul className="d-flex nav nav-neutral-primary justify-content-start ltr">
              <URLSComponent />
            </ul>
          </div>
          <div className="d-flex d-lg-none">
            <span className="d-block d-lg-none">
              <button
                onClick={toggle}
                className={clsx("navbar-toggler hamburger hamburger--elastic", {
                  "is-active": collapse,
                })}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </span>
          </div>
          <div className="d-flex d-lg-none">
            <Collapse isOpen={collapse} className="nav-collapsed-wrapper navbar-collapse">
              <div className="nav-inner-wrapper header-nav-menu">
                <Button onClick={toggle} color="danger" className="btn-icon d-40 shadow-sm hover-scale-lg btn-animated-icon-sm nav-toggle-inner-btn p-0">
                  <span className="btn-wrapper--icon">
                    <GrClose size="20px" />
                    {/* <FontAwesomeIcon icon={['fas', 'times']} /> */}
                  </span>
                </Button>

                <ul className="d-flex flex-column p-3 text-right ltr nav nav-neutral-primary">
                  <URLSComponent />
                </ul>
              </div>
            </Collapse>
          </div>
        </div>
      </header>
    </>
  );
}
