import React from "react";
import { BiStore } from "react-icons/bi";
import { FiStar, FiSettings } from "react-icons/fi";
import { ImGift } from "react-icons/im";
import { GiLargeDress } from "react-icons/gi";
import { AiOutlineLink } from "react-icons/ai";
import { HiOutlineSpeakerphone } from "react-icons/hi";

const Features = (forWho) => {
  if (forWho === "merchants") {
    return [
      {
        title: "خصص نظامك ليناسب إدارتك",
        desc: "مربوط بدومينك شركتك",
        icon: <FiSettings size="30px" />,
      },
      {
        title: "اشحن نظامك بجميع منتجاتك",
        desc: "ليك ولكل مسوقينك",
        icon: <GiLargeDress size="30px" />,
      },
      {
        title: "اربط النظام بدومين شركتك",
        desc: "ليك ولكل مسوقينك",
        icon: <AiOutlineLink size="30px" />,
      },
      {
        title: "اجذب المسوقين لقوة عمولاتك",
        desc: "يتم تغير العملة بسهولة",
        icon: <HiOutlineSpeakerphone size="30px" />,
      },

      // {
      //   title: "جذب مسوقين لنظامك",
      //   // desc: 'مع ترشيح منتاجتك الاكثر مبيعاً والاعلي عمولة',
      //   icon: <RiUser6Line size="30px" />,
      // },
      // {
      //   title: "بدون عمولة",
      //   // desc: 'مجاني تماماً',
      //   icon: <ImGift size="30px" />,
      // },
      // {
      //   title: "اخفاء هويتنا تماماً",
      //   // desc: 'موقع افلييت معمول ليك مخصوص',
      //   icon: <HiBadgeCheck size="30px" />,
      // },
    ];
  }
  return [
    {
      title: "ترشيح المنتجات والانظمة",
      // desc: "مع تقيم مفصل لهم",
      icon: <FiStar size="30px" />,
    },
    {
      title: "متاجر الكترونية بالاسم اللي يناسبك",
      // desc: "مع أمكانية الربط بدومين",
      icon: <BiStore size="30px" />,
    },
    {
      title: "بدون أي عمولة علي أرباحك",
      // desc: "الخدمة مجاناً تماماً للمسوقين",
      icon: <ImGift size="30px" />,
    },
    // {
    //   title: "رسائل دورية",
    //   // desc: "بالمنتجات الاكثر مبيعاً والاعلي عمولة",
    //   icon: <SiMinutemailer size="30px" />,
    // },

    // {
    //   title: "نظام الاحالة",
    //   // desc: "عمولة علي كل مسوق ينضم من خلالك",
    //   icon: <AiOutlineTeam size="30px" />,
    // },
    // {
    //   title: "شروحات بسيطة لكيفية التعامل",
    //   // desc: "يتم شرح كل النقاط التي قد تحتاج لفهمها",
    //   icon: <FaChalkboardTeacher size="30px" />,
    // },
  ];
};
export default Features;
