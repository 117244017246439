
import axios from "axios";
import { URLPLATFORM } from "./api.conf";

export const apiAuthPlatform = (fromData) => {
  return axios.post(URLPLATFORM + "/platformAuthentication/auth", fromData);
};

export const apiLoginPlatform = () => {
  return axios.get(URLPLATFORM + "/platformMe");
};
export const apiGetStories = (filter) => {
  return axios.post(URLPLATFORM + "/getStories", filter);
};

export const apiGetStoreData = (id) => {
  return axios.get(URLPLATFORM + "/feedback/" + id);
};

export const apiPutStoreData = (id, data) => {
  return axios.put(URLPLATFORM + "/feedback/" + id, data);
};

export const apiGetAllPlatformAuthentication = () => {
  return axios.get(URLPLATFORM + "/platformAuthentication");
};
export const apiDeleteAllPlatformAuthentication = () => {
  return axios.delete(URLPLATFORM + "/platformAuthentication/");
};


export const apiGetPlatformAuthenticationByID = (id) => {
  return axios.get(URLPLATFORM + "/platformAuthentication/" + id);
};

export const apiUpdatePlatformAuthenticationByID = (id, platformAuthentication) => {
  return axios.put(URLPLATFORM + "/platformAuthentication/" + id, platformAuthentication);
};

export const apiSavePlatformAuthentication = (platformAuthentication) => {
  return axios.post(URLPLATFORM + "/platformAuthentication", platformAuthentication);
};

export const apiDeletePlatformAuthenticationByID = (id) => {
  return axios.delete(URLPLATFORM + "/platformAuthentication/" + id);
};

