import React from "react";
import { Link } from "react-router-dom";
import illustration1 from "../assets/images/404.svg";
import { Col, Button } from "reactstrap";

class NotFound extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="app-wrapper bg-white rounded">
          <div className="app-main">
            <div className="app-content p-0">
              <div className="app-inner-content-layout--main">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--content">
                    <div className="hero-wrapper bg-composed-wrapper min-vh-100">
                      <div className="flex-grow-1 w-100 d-flex align-items-center">
                        <Col lg="6" md="9" className="px-4 px-lg-0 mx-auto text-center text-black">
                          <img src={illustration1} className="w-50 mx-auto d-block my-5 img-fluid" alt="..." />

                          <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                            هذه الصفحة غير موجودة او غير مصرح لدخولها
                          </h3>
                          <Button tag={Link} color="primary" className="my-2" to={`/`}>
                            <span className="btn-wrapper--label">الصفحة الرئيسية</span>
                          </Button>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NotFound;
