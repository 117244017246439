import React from "react";
import { Row, Col } from "reactstrap";
import CustomContainer from "../components/CustomContainer";
import logoWhite from "../assets/images/logo_white.svg";
import { AiOutlineNumber } from "react-icons/ai";

const SectionStyle = ({ title, paragraph }) => (
  <section className="mt-2">
    <h4 className="display-4 font-weight-bold mb-3">
      <AiOutlineNumber className="ml-1" size="25px" /> {title}
    </h4>
    <p className="font-weight-bold font-size-xl m-0 pb-3 mb-5 text-right text-white opacity-7">{paragraph}</p>
  </section>
);

const Pricing = () => {
  return (
    <>
      <CustomContainer
        Children={
          <Row className="py-5 rtl">
            <Col lg="12">
              <div className="text-center text-lg-left py-0 pr-0 pr-xl-3 py-xl-5 w-100">
                <div className="pr-0 pl-xl-5 text-white rtl w-100 d-flex flex-column justify-content-center justify-content-md-start">
                  <div className="font-size-sm d-inline-flex bg-white-10 px-4 rounded-lg text-white py-2 text-uppercase mt-3 mx-auto mr-md-0 ml-md-auto">
                    <img alt="Affilih Logo" className="mb-2" style={{ width: "8em" }} src={logoWhite} />
                  </div>
                  <h1 className="font-weight-bold mt-4 text-uppercase customH1 text-center text-md-right">الاسعار</h1>
                  {/* Questions */}
                  <div className="w-100 mt-5" />
                  <SectionStyle title="تكلفة الخدمة" paragraph="سعر الطلب الناجح 3 جنيه مصري أي ما يعادل 0.16 دولار أمريكي" />
                  <SectionStyle title="الخدمات الاضافية" paragraph="يمكنك اختيار الباقة المناسبة لطبيعة عملك من صفحة الباقات - الخدمات الإضافية" />
                </div>
              </div>
            </Col>
          </Row>
        }
      />
    </>
  );
};

export default Pricing;
