import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

const ProtectedRouteComonent = ({
  props,
  isAuth,
  profile,
  component: Component,
  allowed,
  nameOfSection,
  notAllowed: NotAllowed,
  ...rest
}) => {

  if (Array.isArray(allowed) && allowed.length !== 0) {
    if (
      allowed.includes(profile.userType) &&
      !profile.userPermissionBlocked.includes(nameOfSection)
    ) {
      return <Route {...rest} render={(props) => <Component key={props.match.params.id}  {...props} />} />;
    }
    return <Route {...rest} render={(props) => <NotAllowed key={props.match.params.id} {...props} />} />;
  }
  return <Route {...rest} render={(props) => <Component key={props.match.params.id}  {...props} />} />;
};
const mapStateToProps = ({ auth }) => {
  return {
    isAuth: auth.isAuth,
    profile: auth.profile,
  };
};

const ProtectedRoute = connect(mapStateToProps)(ProtectedRouteComonent);
export { ProtectedRoute };
