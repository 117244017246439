import React, { useEffect } from "react";
import GetFailedComponent from "../components/GetFailed";
import { Row, Col, Button, Container, ListGroup, ListGroupItem } from "reactstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { GoogleAPI, GoogleLogin } from "react-google-oauth";
import { connect } from "react-redux";
import Features from "../components/Features";
import { FcGoogle } from "react-icons/fc";
import { BsListNested } from "react-icons/bs";
import { addStore, checkDomain, resetDomainChecker, checkDomainIsReady, saveAffiliator } from "../redux/config";
import logoWhite from "../assets/images/logo_white.svg";
import CustomContainer from "../components/CustomContainer";

const CreateAffiliatorComponent = (props) => {
  const {
    saveLoadingAffilitor,
    saveSuccessAffilitor,
    saveFailedAffilitor,

    saveAffiliator,
  } = props;

  useEffect(() => {
    if (saveSuccessAffilitor !== null) {
      window.location.href = saveSuccessAffilitor?.redirectURL;
    }
  }, [saveSuccessAffilitor]);
  const getAccessToken = (e) => {
    if (e[Object.keys(e)[1]]?.access_token !== undefined) {
      return e[Object.keys(e)[1]]?.access_token;
    }
    if (e[Object.keys(e)[2]]?.access_token !== undefined) {
      return e[Object.keys(e)[2]]?.access_token;
    }
    if (e[Object.keys(e)[0]]?.access_token !== undefined) {
      return e[Object.keys(e)[0]]?.access_token;
    }
    return null;
  };
  return (
    <CustomContainer
      Children={
        <Row className="py-5 rtl">
          <Col lg="7">
            <div className="text-center text-lg-left py-0 pr-0 pr-xl-3 py-xl-5 w-100">
              <div className="pr-0 pl-xl-5 text-white rtl w-100 d-flex flex-column justify-content-center justify-content-md-start">
                <div className="font-size-sm d-inline-flex bg-white-10 px-4 rounded-lg text-white py-2 text-uppercase mt-3 mx-auto mr-md-0 ml-md-auto">
                  <img alt="Affilih Logo" className="mb-2" style={{ width: "8em" }} src={logoWhite} />
                </div>
                <h1 className="font-weight-bold mt-4 text-uppercase customH1 text-center text-md-right">منصة المسوقين</h1>
                <p className="font-weight-bold font-size-xl m-0 pb-3 mb-5 text-center text-md-right text-first opacity-7">
                  إنضم إلى أقوى أنظمة التسويق بالعمولة المشتركة معنا
                </p>
                <div className="d-inline-flex flex-column w-100 mt-5">
                  {(() => {
                    if (saveLoadingAffilitor) {
                      return (
                        <SkeletonTheme
                          color="#1D1F2D"
                          highlightColor="#fff"
                          className="d-flex align-items-center flex-row flex-wrap w-100 px-0 justify-content-between opacity-6"
                        >
                          <div className="d-flex align-items-center flex-row flex-wrap w-100 ">
                            <p className="col-12 px-0">
                              <Skeleton height={45} className="w-100 opacity-6" duration={2} />
                            </p>
                          </div>
                        </SkeletonTheme>
                      );
                    } else {
                      return (
                        <>
                          {(() => {
                            if (saveFailedAffilitor !== null) {
                              return (
                                <Container className="text-warning">
                                  <GetFailedComponent failed={saveFailedAffilitor} />
                                </Container>
                              );
                            }
                          })()}
                          <Row className="w-100 mt-4 mx-0 justify-content-center flex-column">
                            <Col sm="12" className="px-sm-0 d-flex align-items-center mt-4">
                              <GoogleAPI clientId="303396594421-ug339mkuhhvepqg4b8i487opunft66bv.apps.googleusercontent.com">
                                <div
                                  style={{
                                    maxWidth: "100%",
                                    display: "inline-block",
                                  }}
                                  className="w-100 rounded overflow-hidden my-2 my-md-0"
                                >
                                  <div>
                                    <GoogleLogin
                                      onLoginSuccess={(e) => {
                                        saveAffiliator({
                                          token: getAccessToken(e),
                                        });
                                      }}
                                      backgroundColor="#fff"
                                      width="100%"
                                      text={
                                        <>
                                          <FcGoogle className="ml-2" size="25px" />
                                          <span className="font-cairo font-weight-bold text-second">إنــضـــم الآن</span>
                                        </>
                                      }
                                    />
                                  </div>
                                </div>
                              </GoogleAPI>
                            </Col>
                          </Row>
                        </>
                      );
                    }
                  })()}
                  <div className="d-flex pt-4">
                    <Button
                      tag="a"
                      href="https://calendly.com/affilih/meet"
                      target="_blank"
                      color="neutral-first"
                      rel="noopener"
                      size="xl"
                      className="w-100 ellipsis-text my-2 my-md-0 mt-md-0 ml-2 rtl rounded d-flex align-items-center justify-content-center font-weight-bold mb-0"
                    >
                      {/* <BsCalendar4Week size="18px" className="ml-1 mb-1" /> */}
                      رتب موعدًا
                    </Button>
                    <Button
                      tag="a"
                      href="https://affiliator.affilih.com"
                      target="_blank"
                      color="neutral-first"
                      size="xl"
                      className="w-100 ellipsis-text my-2 my-md-0 mt-md-0 rtl rounded d-flex align-items-center justify-content-center font-weight-bold mb-0"
                    >
                      <BsListNested size="18px" className="ml-1 mb-1" />
                      تصفح الأنظمة
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="5" className="d-flex align-items-center mt-5 mt-md-0 flex-column justify-content-start rtl">
            <div className="bg-transparent mx-0 mb-5 mt-5 object-skew hover-scale-sm w-100 border-0">
              <ListGroup flush className="pr-0 mt-0 mt-md-5">
                {Features("affiliator")?.map((feature, i) => (
                  <ListGroupItem key={i} tag="a" action href="#/" onClick={(e) => e.preventDefault()} className="d-flex align-items-center py-3 bg-transparent">
                    <div className="d-flex align-items-center">
                      <div className=" ml-3 d-50">
                        <span className="font-weight-bold font-size-xxl text-first">#{i + 1}</span>
                      </div>
                      <div>
                        <div className="font-weight-bold font-size-xl text-white text-right">{feature?.title}</div>
                      </div>
                    </div>
                    <div className="mr-auto font-weight-bold font-size-xxl text-white-50">{feature?.icon}</div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>
        </Row>
      }
    />
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    saveLoadingAffilitor: auth.saveLoadingAffilitor,
    saveSuccessAffilitor: auth.saveSuccessAffilitor,
    saveFailedAffilitor: auth.saveFailedAffilitor,

    checkDomainLoadingStore: auth.checkDomainLoadingStore,
    checkDomainSuccessStore: auth.checkDomainSuccessStore,
    checkDomainFailedStore: auth.checkDomainFailedStore,

    checkDomainIsReady_ReAttempt: auth.checkDomainIsReady_ReAttempt,
    checkDomainIsReady_Loading: auth.checkDomainIsReady_Loading,
    checkDomainIsReady_Success: auth.checkDomainIsReady_Success,
    checkDomainIsReady_Failed: auth.checkDomainIsReady_Failed,
  };
};

const CreateAffiliator = connect(mapStateToProps, {
  addStore,
  saveAffiliator,
  checkDomain,
  resetDomainChecker,
  checkDomainIsReady,
})(CreateAffiliatorComponent);
export default CreateAffiliator;
