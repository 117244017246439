import axios from "axios";
import { URL } from "./api.conf";

export const apiLoginAuthentication = (fromData) => {
  return axios.post(URL + "/auth", fromData);
};
export const apiRegisterAuthentication = (fromData) => {
  return axios.post(URL + "/register", fromData);
};

export const apiGetAllSectionInSettings = () => {
  return axios.get(URL + "/section/settings");
};

export const apiGetProfile = () => {
  return axios.get(URL + "/me");
};

export const apiSendOTP = (data) => {
  return axios.post(URL + "/store/resend", data);
};

export const apiSaveStoreData = (data) => {
  return axios.put(URL + "/store/confirm", data);
};

export const apiCheckDomainStore = (data) => {
  return axios.post(URL + "/store/check/subdomain", data);
};

export const apiConfirmDomainStore = (data) => {
  return axios.post(URL + "/store/confirm/domain", data);
};

export const apiUpdateProfile = (fromData) => {
  return axios.put(URL + "/update", fromData);
};

export const apiGetStore = () => {
  return axios.get(URL + "/myStore");
};

export const apiCheckDomainIsReady = (domain) => {
  return axios.get(domain);
};

export const apiAddStore = ({ token, userPhone }) => {
  return axios.post(URL + "/save/store", { access_token: token, userPhone });
};

export const apiSaveAffiliator = ({ token }) => {
  return axios.post(URL + "/save/affilitor", { access_token: token });
};

export const apiupdateSettings = (settings) => {
  return axios.put(URL + "/settings/", settings);
};
export const apiAddPaymentMethod = (payment) => {
  return axios.post(URL + "/my/payment", payment);
};
export const apiRemovePayment = (id) => {
  return axios.delete(URL + "/my/payment/" + id);
};
export const apiGetUserTreeById = (id) => {
  return axios.get(URL + "/user/tree/" + id);
};
export const apipushAbousUsSocialLinks = (settings) => {
  return axios.put(URL + "/settings/aboutus/socials/", settings);
};
export const apipushCities = (settings) => {
  return axios.put(URL + "/settings/cities/", settings);
};
export const apiUpdatePushCitiesCities = (id, data) => {
  return axios.put(URL + "/settings/update/cities/" + id, data);
};
export const apiRemoveCities = (id) => {
  return axios.delete(URL + "/settings/cities/" + id);
};

export const apipushSections = (sections) => {
  return axios.put(URL + "/settings/sections/", sections);
};

export const apiPushCondition = (sections) => {
  return axios.put(URL + "/settings/condtions/", sections);
};
export const apiRemoveCondition = (id) => {
  return axios.delete(URL + "/settings/condtions/" + id);
};
export const apiUpdatePushSections = (id, data) => {
  return axios.put(URL + "/settings/update/sections/" + id, data);
};
export const apiRemoveSections = (id) => {
  return axios.delete(URL + "/settings/sections/" + id);
};
export const apiRemoveAbousUsSocialLinks = (id) => {
  return axios.delete(URL + "/settings/aboutus/socials/" + id);
};
